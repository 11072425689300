@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hanuman:wght@100;300;400;700&display=swap");
/* RESET CSS */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --color-bg: #00983c;
  --color-textDark: #737373;
  --color-textLight: #737373;
  --color-white: #ffffff;
  --color-light: #4b6a71;
  --color-black: #111820;
  --color-night: #001632;
  --color-red: #f44336;
  --color-blue: #1a73e8;
  --color-gray: #80868b;
  --color-grayish: #dadce0;
  --shadow-normal: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

html {
  font-size: 100%;
  font-size-adjust: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  font-family: "Inter", sans-serif !important;
  color: #313131 !important;
  background: #fff !important;
}
.container_outer_01 {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(28, 66, 139, 1) 50%,
    rgba(28, 66, 139, 1) 100%
  );
  min-height: 100vh;
  height: 100%;
  align-items: center;
  display: flex;
}
.container_outer_02 {
  background: rgb(255, 255, 255);
  /* background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(27,69,141,1) 50%, rgba(28,66,139,1) 100%); */
  /* background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 75%, rgba(27,69,141,1) 75%, rgba(28,66,139,1) 100%); */
  background: linear-gradient(
    180deg,
    rgba(27, 69, 141, 1) 0%,
    rgba(28, 66, 139, 1) 290px,
    rgba(236, 243, 249, 1) 290px,
    rgba(236, 243, 249, 1) 100%
  );
  height: 100%;
}

.loginBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loginBox .left {
  /* justify-content: center;
    align-items: center; */
  width: 50%;
  /* display: flex; */
  /* height: 95vh; */
  /* height: 100%; */
  background: #fff;
}
.loginBox .right {
  /* justify-content: center;
    align-items: center; */
  width: 50%;
  /* display: flex; */
  background: #1c428b;
  /* height: 100vh; */
  /* height: 100%; */
  padding-left: 100px;
}
.loginBoxOuter {
  /* height: 100vh; */
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}
.logo_login {
  width: 320px;
  margin: 0 auto;
}
.login_top {
  text-align: center;
}
.login_top h3 {
  margin-top: 40px;
  font-family: Inter;
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;
}
.login_top p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  color: #868a8e;
  margin-bottom: 25px;
}
.form-control {
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 12px 15px;
  /* height: 66px; */
  font-size: 14px;
  font-weight: 400;
  color: #2d2d2d;
}
.form-select {
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 12px 50px 12px 15px;
  /* height: 66px; */
  min-width: 150px;
  font-size: 14px;
  font-weight: 400;
  color: #2d2d2d;
}
.login_bottom {
}
.mb_12 {
  margin-bottom: 12px;
}
.mb_20 {
  margin-bottom: 20px;
}
.mb_25 {
  margin-bottom: 25px;
}
.mt_10 {
  margin-top: 10px;
}
.mt_20 {
  margin-top: 20px;
}
.forgotPassword {
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
}
.sign_in_button {
  background: #02a1c9;
  width: 100%;
  border-radius: 6px;
  border: 0;
  padding: 12px 15px;
}
.sign_in_button:hover {
  background: #02a1c9;
}
.sign_in_button.deactive {
  background: #c8c8c8;
  width: 100%;
  border-radius: 6px;
  border: 0;
  padding: 12px 15px;
}
.sign_in_button.deactive:hover {
  background: #c8c8c8;
}
.or {
  background: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.or::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background: #dfdfdf;
  left: 0;
  top: 7px;
  text-align: center;
  z-index: 1;
}
.or span {
  background: #fff;
  padding: 0 10px;
  z-index: 2;
  position: relative;
  font-size: 12px;
}
.fb_google {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 8px;
}
.fb_google button,
.fb_google button:hover,
.fb_google button:active {
  background-color: #f0f0f0 !important;
  color: #2d2d2d;
  font-size: 12px;
  border: 0;
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}
.register {
  font-size: 12px;
  line-height: 26px;
  font-weight: 400;
  margin-top: 10px;
}
.register a {
  color: #2a5fc5;
  text-decoration: none;
}
.password_div .input-group input.form-control {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  z-index: 1;
  padding-right: 45px;
  padding-left: 15px;
}
.password_div .input-group .btn {
  border: 0;
  position: absolute;
  right: 14px;
  top: 8px;
  z-index: 2;
}
.password_div .input-group .btn:hover {
  background-color: transparent;
}

/* Agent Update Profile */

.AgentUpdateProfile_top {
  height: 100px;
  padding-top: 35px;
}
.AgentUpdateProfile_bottom {
  min-height: calc(100vh - 200px);
  height: 100%;
  background: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin-bottom: 100px;
}
.AgentUpdateProfile_bottom .left {
  background: #ecf3f9;
  width: 234px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AgentUpdateProfile_bottom .right {
  width: calc(100% - 234px);
  overflow-y: auto;
  padding: 30px;
  padding-bottom: 100px;
}
.user_circle {
  width: 97px;
  height: 97px;
  border-radius: 100px;
  border: 2px solid #cdcdcd;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  position: relative;
}
.user_circle_edit {
  position: absolute;
  border-radius: 100px;
  width: 37px;
  height: 37px;
  bottom: -12px;
  right: 5px;
  background-color: #e3eaf2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_name {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}
.AgentUpdateProfile_bottom .right .top {
  display: flex;
  justify-content: flex-end;
  height: 24px;
}
.AgentUpdateProfile_bottom .right .middle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.AgentUpdateProfile_bottom .right .middle .formContainer {
  width: 320px;
}
.formContainer .login_top {
  margin-bottom: 60px;
}
.formContainer .label_text {
  color: #868a8e;
  font-size: 14px;
  font-weight: 400;
}

.progress {
  background: #e3e7eb;
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0px;
  display: flex;
  height: 4px;
  width: 200px;
}

.progress-value {
  animation: load 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #f90;
  height: 4px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }
  /* 100% { width: 100%; } */
}
.progress_outer p {
  font-size: 12px;
  font-weight: 500;
  color: #2d2d2d;
  display: flex;
  justify-content: space-between;
  opacity: 0.7;
  padding-bottom: 5px;
}
.progress_outer p span {
  color: #1c428b;
}

/* Select your Plan */

.twoPlan {
  display: flex;
  gap: 40px;
}
.planBox {
  width: 245px;
  padding: 40px 24px 35px 24px;
  border-radius: 16px;
  text-align: center;
}
.redBox {
  background-color: #fc867e;
}
.purpleBox {
  background-color: #ab6ade;
}
.planBox h2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  margin-bottom: 10px;
}
.planBox .smallText {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  margin-bottom: 10px;
}
.planBox .amount {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  margin-bottom: 50px;
}
.redBox button,
.redBox button:hover,
.redBox button:hover,
.redBox .btn:focus-visible,
.redBox .btn-check:checked + .btn,
.redBox .btn.active,
.redBox .btn.show,
.redBox .btn:first-child:active,
.redBox :not(.btn-check) + .btn:active {
  width: 100%;
  color: #fc867e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 14px */
  background-color: #fff;
  border-radius: 6px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  border: 0;
  box-shadow: none;
}

.purpleBox button,
.purpleBox button:hover,
.purpleBox button:hover,
.purpleBox .btn:focus-visible,
.purpleBox .btn-check:checked + .btn,
.purpleBox .btn.active,
.purpleBox .btn.show,
.purpleBox .btn:first-child:active,
.purpleBox :not(.btn-check) + .btn:active {
  width: 100%;
  color: #ab6ade;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 14px */
  background-color: #fff;
  border-radius: 6px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  border: 0;
  box-shadow: none;
}
.select_plan_process {
  margin-top: 100px;
}
.select_plan_process div {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.select_plan_process span {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #868a8e;
  line-height: 24px;
}
.select_plan_process li {
  margin-bottom: 40px;
  position: relative;
}
.select_plan_process li::after {
  content: "";
  height: 30px;
  width: 1px;
  background-color: #cfcfcf;
  position: absolute;
  left: 50%;
  bottom: -32px;
}
.select_plan_process li:last-child:after {
  display: none;
}
.select_plan_process .active span {
  color: #2d2d2d;
}
.select_plan_process .completed span {
  color: #1c428b;
}
.select_plan_process li.completed::after {
  background-color: #1c428b;
}

/* Select your payment option */
.sky_light_box {
  border: 1px solid #cdf2e7;
  background: #e1fff6;
}
.green_light_box {
  border: 1px solid #d9f2d5;
  background: #effeed;
}
.payBox h2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #2d2d2d;
  margin-bottom: 25px;
  margin-top: 20px;
}
.payBox {
  width: 245px;
  padding: 20px 24px 25px 24px;
  border-radius: 16px;
  text-align: center;
}
.sky_light_box button,
.sky_light_box button:hover,
.sky_light_box button:hover,
.sky_light_box .btn:focus-visible,
.sky_light_box .btn-check:checked + .btn,
.sky_light_box .btn.active,
.sky_light_box .btn.show,
.sky_light_box .btn:first-child:active,
.sky_light_box :not(.btn-check) + .btn:active {
  width: 116px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  background-color: #02a1c9;
  border-radius: 6px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  border: 0;
  box-shadow: none;
}
.green_light_box button,
.green_light_box button:hover,
.green_light_box button:hover,
.green_light_box .btn:focus-visible,
.green_light_box .btn-check:checked + .btn,
.green_light_box .btn.active,
.green_light_box .btn.show,
.green_light_box .btn:first-child:active,
.green_light_box :not(.btn-check) + .btn:active {
  width: 116px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  background-color: #02a1c9;
  border-radius: 6px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  border: 0;
  box-shadow: none;
}
.card_number {
  display: flex;
  flex-direction: row;
  gap: 17px;
}
.card_number input {
  width: 25%;
}
.ExpiryDateOuter {
  display: flex;
  flex-direction: row;
  gap: 17px;
}
.ExpiryDate {
  width: 50%;
}
.SuccessBlueTicIcon_outer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.SuccessBlueTicIcon {
  width: 320px;
  border: 1px solid #d2d2d2;
  padding: 50px 25px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}
.SuccessBlueTicIcon p {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px; /* 120% */
}
.SuccessBlueTicIcon button {
  width: 90%;
}

/* Admin Dashboard */
.AdminContainer {
  display: flex;
  background-color: #ecf3f9;
  min-height: 100vh;
  height: 100%;
}
.AdminContainer > .left {
  width: 239px;
  background-color: #fff;
  padding: 28px 0 0 40px;
}
.AdminContainer > .right {
  width: calc(100% - 239px);
}
.sidebar-toggle .AdminContainer > .left {
  width: 90px;
  background-color: #fff;
  padding: 28px 0 0 40px;
}
.sidebar-toggle .AdminContainer > .right {
  width: calc(100% - 90px);
}

.AdminContainer .header {
  background-color: #e2e9ee;
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
}
.AdminContainer .left img {
  cursor: pointer;
}
/* .container-fluid { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; } */
.container-fluid {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}
.HeaderRight {
  display: flex;
  gap: 23px;
  justify-content: center;
  align-items: center;
}
.notification {
  position: relative;
}
.notificationCirle {
  width: 19px;
  height: 19px;
  background-color: #d71635;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #fff;
  border-radius: 100px;
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
  right: -11px;
  top: 0;
}
.AdminDashboardContainer {
  padding: 37px 40px 40px 40px;
  height: calc(100% - 120px);
}
.AdminDashboardContainer .searchBar {
  padding: 30px 40px;
  background-color: #1c428b;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.AdminDashboardContainer .searchBar ul {
  display: flex;
  color: #fff;
  gap: 10px;
  align-items: center;
}
.AdminDashboardContainer .searchBar ul li:first-child {
  font-size: 20px;
  font-weight: 700;
  line-height: 56px;
}
.AdminDashboardContainer .searchBar ul .searchButton {
  background-color: #02a1c9;
  color: #fff;
  border: 0;
  border-radius: 4px;
  padding: 12px 15px;
  width: 200px;
  font-weight: 600;
}
.searchButtonBorderTransparentBg,
.searchButtonBorderTransparentBg:hover,
.searchButtonBorderTransparentBg:first-child:active {
  background-color: transparent;
  color: #fff;
  border: 0;
  border-radius: 4px;
  padding: 12px 15px;
  width: 200px;
  border: 1px solid #02a1c9;
  font-weight: 600;
}
.searchButtonBorderTransparentBg.btn-check:checked + .btn,
.searchButtonBorderTransparentBg.btn.active,
.searchButtonBorderTransparentBg.btn.show,
.searchButtonBorderTransparentBg.btn:first-child:active,
.searchButtonBorderTransparentBg :not(.btn-check) + .btn:active {
  background-color: transparent;
  color: #fff;
  border: 0;
  border-radius: 4px;
  padding: 12px 15px;
  width: 200px;
  border: 1px solid #02a1c9;
  font-weight: 600;
}
.AdminDashboardContainer .searchBar .cusomerName {
  width: 30%;
}
.AdminDashboardContainer .searchBar .selectProduct {
  width: 30%;
}
.AdminDashboardContainer .searchBar .selectProduct {
  width: 30%;
}

/* left Menu start  */
.mainMenu a {
  text-decoration: none;
  color: #2d2d2d;
  display: flex;
  width: 100%;
  height: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 6px 15px 13px 35px;
  position: relative;
}
.mainMenu a.active,
.mainMenu a:hover {
  color: #1c428b;
  position: relative;
}
/* .mainMenu a.active::before {
  width: 100%;
  height: 1px;
  content: '';
  background: #FF9900;
  position: absolute;
  bottom: -5px;
} */
.headerMainContainer .mainMenu a.active,
.headerMainContainer .mainMenu a:hover {
  color: #ff9900;
  position: relative;
}
.headerMainContainer .mainMenu a.active::before {
  width: 100%;
  height: 1px;
  content: "";
  background: #ff9900;
  position: absolute;
  bottom: -5px;
}
.mainMenu a:hover::after {
  content: "";
  width: 4px;
  height: 31px;
  position: absolute;
  background-color: #02a1c9;
  top: -4px;
  right: 0;
}
.mainMenu .Dashboard a {
  background: url(/public/images/Dashboard_deactive.svg) no-repeat 0px 50%;
}
/* .mainMenu .Dashboard a.active::after, .mainMenu .Dashboard a:hover::after { */
.mainMenu a.active::after,
.mainMenu a:hover::after {
  content: "";
  width: 4px;
  height: 31px;
  position: absolute;
  background-color: #02a1c9;
  top: -4px;
  right: 0;
}
.sidebar-toggle .mainMenu .Dashboard a.active::after,
.sidebar-toggle .mainMenu .Dashboard a:hover::after {
  display: none;
}
.mainMenu .Dashboard a.active,
.mainMenu .Dashboard a:hover {
  background: url(/public/images/Dashboard_active.svg) no-repeat 0px 50%;
}
.mainMenu .Products a {
  background: url(/public/images/Products_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .Products a.active,
.mainMenu .Products a:hover {
  background: url(/public/images/Products_active.svg) no-repeat 0px 50%;
}
.mainMenu .Carriers a {
  background: url(/public/images/Carriers_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .Carriers a.active,
.mainMenu .Carriers a:hover {
  background: url(/public/images/Carriers_active.svg) no-repeat 0px 50%;
}
.mainMenu .ManageSubscription a {
  background: url(/public/images/ManageSubscription_deactive.svg) no-repeat 0px
    50%;
}
.mainMenu .ManageSubscription a.active,
.mainMenu .ManageSubscription a:hover {
  background: url(/public/images/ManageSubscription_active.svg) no-repeat 0px
    50%;
}
.mainMenu .Agency a {
  background: url(/public/images/Agency_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .Agency a.active,
.mainMenu .Agency a:hover {
  background: url(/public/images/Agency_active.svg) no-repeat 0px 50%;
}
.mainMenu .SearchHistory a {
  background: url(/public/images/SearchHistory_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .SearchHistory a.active,
.mainMenu .SearchHistory a:hover {
  background: url(/public/images/SearchHistory_active.svg) no-repeat 0px 50%;
}
.mainMenu .Report a {
  background: url(/public/images/Report_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .Report a.active,
.mainMenu .Report a:hover {
  background: url(/public/images/Report_active.svg) no-repeat 0px 50%;
}
.mainMenu .Payments a {
  background: url(/public/images/Payments_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .Payments a.active,
.mainMenu .Payments a:hover {
  background: url(/public/images/Payments_active.svg) no-repeat 0px 50%;
}
.mainMenu .UserManagement a {
  background: url(/public/images/UserManagement_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .UserManagement a.active,
.mainMenu .UserManagement a:hover {
  background: url(/public/images/UserManagement_active.svg) no-repeat 0px 50%;
}

/* edit 4 left menus */
.mainMenu .FAQTopics a {
  background: url(/public/images/UserManagement_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .FAQTopics a.active,
.mainMenu .FAQTopics a:hover {
  background: url(/public/images/UserManagement_active.svg) no-repeat 0px 50%;
}

.mainMenu .FAQDetails a {
  background: url(/public/images/UserManagement_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .FAQDetails a.active,
.mainMenu .FAQDetails a:hover {
  background: url(/public/images/UserManagement_active.svg) no-repeat 0px 50%;
}

.mainMenu .ContactUs a {
  background: url(/public/images/UserManagement_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .ContactUs a.active,
.mainMenu .ContactUs a:hover {
  background: url(/public/images/UserManagement_active.svg) no-repeat 0px 50%;
}

.mainMenu .Reviews a {
  background: url(/public/images/UserManagement_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .Reviews a.active,
.mainMenu .Reviews a:hover {
  background: url(/public/images/UserManagement_active.svg) no-repeat 0px 50%;
}

.mainMenu .OurTeam a {
  background: url(/public/images/UserManagement_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .OurTeam a.active,
.mainMenu .OurTeam a:hover {
  background: url(/public/images/UserManagement_active.svg) no-repeat 0px 50%;
}


/* edit 4 left menus */

.mainMenu .Logout a {
  background: url(/public/images/Logout_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .Logout a.active,
.mainMenu .Logout a:hover {
  background: url(/public/images/Logout_active.svg) no-repeat 0px 50%;
}
/* .mainMenu .UserManagement a {
  background: #fff url(/public/images/UserManagement_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .UserManagement a.active, .mainMenu .UserManagement a:hover {
  background: #fff url(/public/images/UserManagement_active.svg) no-repeat 0px 50%;
} */
.mainMenu li {
  margin: 17px 0;
  padding: 10px 0;
}
.sidebar-toggle .mainMenu a {
  position: relative;
}
.sidebar-toggle .mainMenu a span {
  display: none;
}
.sidebar-toggle .mainMenu a:hover span {
  height: 40px;
  width: 167px;
  background: #02a1c9;
  position: absolute;
  top: -4px;
  left: 39px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #fff;
}

/* left Menu end  */

/* Products */

.head_bread {
  display: flex;
  justify-content: space-between;
}
.head_bread h1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #000;
}
.head_bread div {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #1c428b;
}
.head_bread a,
.head_bread a:hover {
  color: #1c428b;
  text-decoration: none;
}
.head_bread div span {
  color: #7a7a7a;
}
.AdminDashboardWhiteBody {
  background-color: #fff;
  height: 100%;
  margin-top: 30px;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding-bottom: 50px;
}
.AdminDashboardWhiteBody ul {
  display: flex;
  justify-content: flex-start;
  /* padding-left: 5em; */
  margin: 0 auto;
  gap: 40px;
  flex-wrap: wrap;
}
.AdminDashboardWhiteBody ul.box4 li {
  display: flex;
  width: 213px;
  padding: 60px 0 50px 0;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
}
.AdminDashboardWhiteBody ul.box4 li .imageColorWhite {
  cursor: pointer;
}
.AdminDashboardWhiteBody ul li.HomeProduct {
  background: #849ae5;
}
.AdminDashboardWhiteBody ul li.AutoProduct {
  background: #daae57;
}
.AdminDashboardWhiteBody ul li.DwellingFireProduct {
  background: #ae7ec4;
}
.AdminDashboardWhiteBody ul li.ManufacturedProduct {
  background: #e99e74;
}
.ProductsPage .ProductCategories {
  text-align: center;
  padding: 100px;
  padding-bottom: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  gap: 10px;
}
.AdminDashboardWhiteBody a {
  text-decoration: none;
}
.AdminDashboardWhiteBody ul li p {
  margin-top: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 0 20px;
  text-align: center;
  height: 45px;
}
/* different buttons  */
.button_redBorder,
.button_redBorder:hover,
.button_redBorder.btn:first-child:active {
  border-radius: 4px;
  border: 1px solid #f4392d;
  /* background: #fff; */
  background: transparent;
  color: #f4392d;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  display: flex;
  padding: 9px 34px;
  font-size: 14px;
  box-shadow: none;
}
.button_grayBorder,
.button_grayBorder:hover,
.button_grayBorder.btn:first-child:active {
  border-radius: 4px;
  border: 1px solid #c9c9c9;
  /* background: #fff; */
  background: transparent;
  color: #868a8e;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  display: flex;
  padding: 9px 34px;
  font-size: 14px;
  box-shadow: none;
}
.button_blueBorder,
.button_blueBorder.btn:first-child:active {
  border-radius: 4px;
  border: 1px solid #02a1c9;
  /* background: #fff; */
  background: transparent;
  color: #02a1c9;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 5px;
  font-size: 14px;
}
.button_blueBorder:hover {
  border: 1px solid #02a1c9;
  /* background: #fff; */
  background: transparent;
  color: #02a1c9;
}
.button_blueBorder_bgTransparent {
  border-radius: 4px;
  border: 1px solid #02a1c9;
  background: transparent;
  color: #02a1c9;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  display: flex;
  padding: 11px 24px;
  font-size: 14px;
}
.button_blueBorder_bgTransparent:hover {
  border: 1px solid #02a1c9;
  background: transparent;
  color: #02a1c9;
}
.button_blueBorder_small {
  border-radius: 4px;
  border: 1px solid #02a1c9;
  background: #fff;
  color: #02a1c9;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  display: flex;
  padding: 8px 16px;
  font-size: 13px;
}
.button_blueBorder_small:hover {
  border: 1px solid #02a1c9;
  background: #fff;
  color: #02a1c9;
}
.button_blueBorder.btn-check:checked + .button_blueBorder.btn,
.button_blueBorder.btn.active,
.button_blueBorder.btn.show,
.button_blueBorder.btn:first-child:active,
:not(.btn-check) + .button_blueBorder.btn:active {
  border: 1px solid #02a1c9;
  /* background: #fff; */
  background-color: transparent;
  color: #02a1c9;
}
.button_blueBorder_container_small {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.button_blueBorder_container_small a {
  text-decoration: none;
}
.button_blueBorder_small {
  border-radius: 4px;
  border: 1px solid #02a1c9;
  background: #fff;
  color: #02a1c9;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 8px 14px;
  font-size: 12px;
}
.button_blueBorder_small:hover {
  border: 1px solid #02a1c9;
  background: #fff;
  color: #02a1c9;
}
.button_area {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

/* Products end */

/* Products modal start */

.modal_body {
  padding: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.modal_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.modal_top .left h1 {
  padding-bottom: 30px;
  font-weight: 600;
}
.modal_top .right {
  position: relative;
}
.modal_top .right img {
  cursor: pointer;
}
.modalCloseIcon {
  position: absolute;
  right: -20px;
  top: -20px;
}
.AddNewCategoryModal .label_text {
  font-size: 12px;
  color: #868a8e;
  font-weight: 500;
  margin-bottom: 10px;
}
.AddNewCategoryModal .form-control {
  font-size: 14px;
  color: #2d2d2d;
  font-weight: 500;
}

/* Products modal end */

/* This css is for normalizing styles. You can skip this. -start */

.SelectColor .new {
  /* padding: 50px; */
  display: flex;
  flex-wrap: wrap;
  gap: 12px 22px;
}

.SelectColor .form-group {
  display: block;
  /* margin-bottom: 15px; */
}

.SelectColor .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.SelectColor .form-group label {
  position: relative;
  cursor: pointer;
}

.SelectColor .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 0px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 12px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 100px;
}

.SelectColor .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 9px;
  width: 5px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.SelectColor .form-group label.color_01::before {
  background-color: #65cfc9;
}
.SelectColor .form-group label.color_02::before {
  background-color: #e99e74;
}
.SelectColor .form-group label.color_03::before {
  background-color: #daae57;
}
.SelectColor .form-group label.color_04::before {
  background-color: #df6a6a;
}
.SelectColor .form-group label.color_05::before {
  background-color: #528be0;
}
.SelectColor .form-group label.color_06::before {
  background-color: #9beaac;
}
.SelectColor .form-group label.color_07::before {
  background-color: #ffb35a;
}
.SelectColor .form-group label.color_08::before {
  background-color: #d9ca42;
}
.SelectColor .form-group label.color_09::before {
  background-color: #8eb485;
}
.SelectColor .form-group label.color_10::before {
  background-color: #ff2257;
}
.SelectColor .form-group label.color_11::before {
  background-color: #b95691;
}
.SelectColor .form-group label.color_12::before {
  background-color: #ae7ec4;
}
.SelectColor .form-group label.color_13::before {
  background-color: #849ae5;
}
.SelectColor .form-group label.color_14::before {
  background-color: #b96666;
}
.SelectColor .form-group label.color_15::before {
  background-color: #bba4fc;
}
.SelectColor .form-group label.color_16::before {
  background-color: #f86588;
}

/* This css is for normalizing styles. You can skip this. --- end */

/* custom check box with green tic start */
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* max-width: 600px; */
  user-select: none;
}
.checkbox-group > * {
  margin: 0 7px;
}
.checkbox-group-legend {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9c9c9c;
  text-align: center;
  line-height: 1.125;
  margin-bottom: 1.25rem;
}
.checkbox-input {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.checkbox-input[type="checkbox"]:checked + .checkbox-tile {
  border-color: #2260ff;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #2260ff;
}
.checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #74ba43;
  border-color: #74ba43;
}
.checkbox-input[type="checkbox"]:checked + .checkbox-tile .checkbox-icon,
.checkbox-input[type="checkbox"]:checked + .checkbox-tile .checkbox-label {
  color: #2260ff;
}
.checkbox-input:focus + .checkbox-tile {
  border-color: #2260ff;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc; */
}
.checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}
.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36px;
  min-height: 36px;
  border-radius: 3px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}
.checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 13px;
  height: 13px;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: -7px;
  right: -7px;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.checkbox-tile:hover {
  border-color: #2260ff;
}
.checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}
.checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
  padding-top: 5px;
}
.checkbox-icon img {
  width: 25px;
  height: 24px;
}
.checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}
.checkbox-group .checkbox:first-child {
  margin-left: 0;
}
.checkbox-group .checkbox:last-child {
  margin-right: 0;
}
.checkbox-input:checked + .checkbox-tile > .checkbox-icon {
  opacity: 0.5;
}
/* custom check box with green tic end */

/* custom check box border box start */
.borderBoxCheckBox .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* max-width: 600px; */
  user-select: none;
  gap: 6px;
}
.borderBoxCheckBox .checkbox-group > * {
  margin: 0;
}
.borderBoxCheckBox .checkbox-group-legend {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9c9c9c;
  text-align: center;
  line-height: 1.125;
  margin-bottom: 1.25rem;
}
.borderBoxCheckBox .checkbox-input {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.borderBoxCheckBox .checkbox-input[type="checkbox"]:checked + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #02a1c9;
}
.borderBoxCheckBox .checkbox-input[type="radio"]:checked + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #02a1c9;
}
.borderBoxCheckBox
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #02a1c9;
}

.borderBoxCheckBox .checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #74ba43;
  border-color: #74ba43;
}
.borderBoxCheckBox
  .checkbox-input[type="checkbox"]:checked
  + .checkbox-tile
  .checkbox-icon,
.checkbox-input[type="checkbox"]:checked + .checkbox-tile .checkbox-label {
  color: #02a1c9;
}

.borderBoxCheckBox .checkbox-input:focus + .checkbox-tile {
  /* border-color: #02a1c9; */
  border-color: #e7e7e7;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc; */
}
.borderBoxCheckBox .checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}
.borderBoxCheckBox .checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  min-height: 36px;
  border-radius: 3px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  padding: 0 14px;
}
.borderBoxCheckBox .checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 13px;
  height: 13px;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: -7px;
  right: -7px;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: none;
}
.borderBoxCheckBox .checkbox-tile:hover {
  border-color: #02a1c9;
}
.borderBoxCheckBox .checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}
.borderBoxCheckBox .checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
  padding-top: 5px;
}
.borderBoxCheckBox .checkbox-icon img {
  width: 25px;
  height: 24px;
}
.borderBoxCheckBox .checkbox-label {
  color: #868a8e;
  transition: 0.375s ease;
  text-align: center;
  font-size: 14px;
}
.borderBoxCheckBox .checkbox-group .checkbox:first-child {
  margin-left: 0;
}
.borderBoxCheckBox .checkbox-group .checkbox:last-child {
  margin-right: 0;
}
.borderBoxCheckBox .checkbox-input:checked + .checkbox-tile > .checkbox-icon {
  opacity: 0.5;
}
/* custom check box with border box end */

/* Product home */
.AdminDashboardWhiteBody570 {
  width: 570px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 30px;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  /* padding-bottom: 50px; */
}
.AdminDashboardWhiteBody570 .top {
  display: flex;
  padding: 35px 35px 0 35px;
  justify-content: space-between;
  align-items: center;
}
.AdminDashboardWhiteBody570 .h1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.AdminDashboardWhiteBody570 .middle {
  padding: 30px;
}
.formBox01 {
  border: 1px solid #eaeaea;
  width: 500px;
  padding: 14px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.formBox01:last-child {
  margin-bottom: 0;
}
.AdminDashboardWhiteBody570 .formBox01 .topPart {
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin-bottom: 16px;
}
.AdminDashboardWhiteBody570 .formBox01 .topPart h2 {
  font-size: 14px;
  font-weight: 500;
}
.AdminDashboardWhiteBody570 .formBox01 .topPart div {
  display: flex;
  gap: 15px;
}

.AdminDashboardWhiteBody390 {
  width: 390px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 30px;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 35px;
}
.CarrierDetails .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.CarrierDetails .middle label {
  font-size: 14px;
  font-weight: 500;
  color: #2d2d2d;
  opacity: 0.7;
  margin-bottom: 9px;
}
.CarrierDetails .middle p {
  font-size: 14px;
  font-weight: 600;
  color: #2d2d2d;
  margin-bottom: 20px;
}

.twoOptions > div {
  /* width: 49%; */
}
.twoOptions .checkbox-wrapper {
  width: 100%;
}
.borderBoxCheckBox
  .twoOptions
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile {
  border-color: #55bcaa;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  background-color: #55bcaa;
}
.borderBoxCheckBox
  .twoOptions
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #fff;
}
.borderBoxCheckBox .twoOptions .checkbox-tile:hover {
  border-color: #55bcaa;
}
.twoOptions .checkbox-tile {
  height: 47px;
}
.options_box_outer {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}
.options_box {
  display: flex;
  gap: 10px;
  color: #1c428b;
  font-weight: 400;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid #1c428b;
  padding: 8px 14px;
}
.options_box img {
  cursor: pointer;
}

/* ------------------ Carriers start ------------------------- */

/* custom check box border box start */
.orangeActiveDeactive .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* max-width: 600px; */
  user-select: none;
  gap: 6px;
}
.orangeActiveDeactive .checkbox-group > * {
  margin: 0;
}
.orangeActiveDeactive .checkbox-group-legend {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9c9c9c;
  text-align: center;
  line-height: 1.125;
  margin-bottom: 1.25rem;
}
.orangeActiveDeactive .checkbox-input {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.orangeActiveDeactive
  .checkbox-input[type="checkbox"]:checked
  + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #02a1c9;
}
.orangeActiveDeactive .checkbox-input[type="radio"]:checked + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #02a1c9;
}
.orangeActiveDeactive
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #02a1c9;
}

.orangeActiveDeactive .checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #f90;
  border-color: #f90;
}
.orangeActiveDeactive
  .checkbox-input[type="checkbox"]:checked
  + .checkbox-tile
  .checkbox-icon,
.checkbox-input[type="checkbox"]:checked + .checkbox-tile .checkbox-label {
  color: #02a1c9;
}

.orangeActiveDeactive .checkbox-input:focus + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc; */
}
.orangeActiveDeactive .checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}
.orangeActiveDeactive .checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  min-height: 36px;
  border-radius: 3px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  padding: 0 24px;
}
.orangeActiveDeactive .checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 13px;
  height: 13px;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: -7px;
  right: -7px;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: none;
}
.orangeActiveDeactive .checkbox-tile:hover {
  border-color: #f90;
}
.orangeActiveDeactive .checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}
.orangeActiveDeactive .checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
  padding-top: 5px;
}
.orangeActiveDeactive .checkbox-icon img {
  width: 25px;
  height: 24px;
}
.orangeActiveDeactive .checkbox-label {
  color: #868a8e;
  transition: 0.375s ease;
  text-align: center;
  font-size: 14px;
}
.orangeActiveDeactive .checkbox-group .checkbox:first-child {
  margin-left: 0;
}
.orangeActiveDeactive .checkbox-group .checkbox:last-child {
  margin-right: 0;
}
.orangeActiveDeactive
  .checkbox-input:checked
  + .checkbox-tile
  > .checkbox-icon {
  opacity: 0.5;
}
.orangeActiveDeactive
  .twoOptions
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile {
  border-color: #f90;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  background-color: #f90;
}
.orangeActiveDeactive
  .twoOptions
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #fff;
}
/* custom check box with border box end */

.whiteBodyPadding {
  padding: 20px;
}
.Container_body {
  margin-top: 35px;
}
.Container_body .twoPart {
  display: flex;
  justify-content: space-between;
}
.Container_body .twoPart .right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Container_body .twoPart .right a {
  text-decoration: none;
}

.carriers_item {
  margin-top: 20px;
}
.carriers_item ul {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
}
.carriers_item ul li {
  /* width: calc(20% - 20px); */
  width: 18.4%;
  /* border: 1px solid #000; */
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  min-height: 230px;
  padding: 20px;
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.carriers_item ul li:nth-child(5n) {
  margin-right: 0;
}
.carriers_item ul li .image_container {
  height: 137px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.carriers_item ul li .twoPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.carriers_item ul li h3 {
  font-size: 13px;
  font-weight: 500;
  color: #2d2d2d;
}
.carriers_item ul li .twoPart .left {
  font-size: 9.6px;
  color: #2d2d2d;
  opacity: 0.7;
}

.carriers_item ul li a {
  text-decoration: none;
}
.carriers_item ul li .image_container .twoPart .left {
}

/* ------------------ Carriers start ------------------------- */

/* circle progress bar start */
@keyframes progress {
  0% {
    --percentage: 0;
  }
  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

[role="progressbar"] {
  --percentage: var(--value);
  --primary: #60c761;
  --secondary: #e7e7e7;
  --size: 35px;
  animation: progress 2s 0.5s forwards;
  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
}

[role="progressbar"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    var(--primary) calc(var(--percentage) * 1%),
    var(--secondary) 0
  );
  mask: radial-gradient(white 55%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(#0000 55%, #000 0);
  -webkit-mask-mode: alpha;
}

[role="progressbar"]::after {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
  /* font-family: Helvetica, Arial, sans-serif; */
  font-size: 10px;
  color: #868a8e;
  font-weight: 700;
}

/* demo */

/* circle progress bar start */

.carriersLeft {
  background: #ecf3f9;
  width: 234px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carriersRight {
  width: calc(100% - 234px);
}
.AdminDashboardWhiteBody ul.displayCarriersInnerLeft {
  display: inherit;
}
.carriersRight .modal_body {
  /* width: 320px; */
  /* width: 357px; */
  width: 500px;
  margin: 0 auto;
  padding: 80px 0;
}
.carriersRight .modal_body .headText {
  text-align: center;
  font-size: 23px;
}
.carriersRight .modal_body .label_text {
  font-size: 12px;
  color: #868a8e;
  font-weight: 500;
  margin-bottom: 10px;
}
.agent_box .top {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}
.agent_box .bottom {
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
}
.agent_box .right.colorBlue {
  width: 25px;
}

.AvencyPage .table_area {
  margin-top: 40px;
}
.AvencyPage .table_area thead tr th {
  background: #e3eaf2;
}
.AvencyPage .table_area table {
  font-size: 13px;
  color: #5f5f5f;
}
.AvencyPage .table_area table tr {
  font-weight: 600;
}
.AvencyPage .table_area table td {
  font-weight: 500;
}
.AvencyPage .table_area table td,
.AvencyPage .table_area table th {
  padding: 18px;
}
.table-striped > tbody > tr:nth-of-type(2n + 1) > * {
  --bs-table-bg-type: #fff;
}
.table.whiteTableTd > :not(caption) > * > * {
  background-color: #fff;
}
.table.whiteTableTd > :not(caption) > * > * {
  padding: 0 0.5rem;
}
.table {
  margin-bottom: 0;
}
.table > :not(caption) > * > * {
  background-color: #f4f4f4;
}
.shorting_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.shorting {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.shorting img {
  padding: 2px;
  cursor: pointer;
}
.action_icon_container {
  display: flex;
  gap: 10px;
}

.action_icon_container img {
  cursor: pointer;
}
.switchModal .modal-header {
  border-bottom: 0;
}
.switchModal .modal-body {
  padding: 0 20px 50px 20px;
}
.switchModal h3 {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  margin-bottom: 20px;
}
.AgencyDetailsTable thead th {
  color: #868a8e;
  font-weight: 400;
  font-size: 15px;
  min-width: 200px;
  width: 16%;
}
.AgencyDetailsTable tbody td {
  color: #2d2d2d;
  font-weight: 500;
  font-size: 17px;
}
.agencyListing {
  display: flex;
  gap: 50px;
}
.agencyListingData {
  font-size: 13px;
  color: #2d2d2d;
  font-weight: 500;
}
.agencyListingData span {
  color: #868a8e;
  font-weight: 400;
}
.agencyListingData li {
  margin-bottom: 10px;
}
.agencyListingData li:last-child {
  margin-bottom: 0;
}
.searchHistoryFormBar {
  display: flex;
  gap: 10px;
}
.search_icon.input-group .dateButton,
.search_icon.input-group .dateButton:hover {
  display: flex;
  top: 9px;
  right: 8px;
}
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dateButtonContainer {
  width: 150px;
}
.dateButtonContainer2 {
  width: 250px;
}
.button_blue,
.button_blue:hover {
  background: #02a1c9;
  border: 2px solid #02a1c9;
  border-color: #02a1c9 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 9px 40px 9px 40px;
  color: #fff;
}
.button_blue_100,
.button_blue_100:hover {
  background: #02a1c9;
  border: 2px solid #02a1c9;
  border-color: #02a1c9 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 9px 40px 9px 40px;
  width: 100%;
}
.SearchHistoryDetail_modal {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
  margin-bottom: 20px;
}
.SearchHistoryDetail_modal > li {
  width: 25%;
}
.SearchHistoryDetail_modal_inner li:first-child {
  font-size: 15px;
  font-weight: 400;
  color: #868a8e;
}
.SearchHistoryDetail_modal_inner li:last-child {
  font-size: 17px;
  font-weight: 500;
  color: #2d2d2d;
  line-height: 20px;
}
.SearchHistoryModal h2 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #bebebe;
}
.SearchHistoryModal .modal-header {
  border-bottom: 0;
}
.SearchHistoryModal .modal-body {
  padding: 0 60px 60px 60px;
}
.SearchHistoryModal .accordion-item {
  border: 0;
}
.SearchHistoryModal .accordion-button:not(.collapsed) {
  background-color: #fff;
  padding: 10px 0;
  color: #f90;
  font-size: 15px;
  font-weight: 600;
}
.SearchHistoryModal .accordion-button {
  background-color: #fff;
  padding: 10px 0;
  color: #2d2d2d;
  font-size: 15px;
  font-weight: 600;
}
.SearchHistoryModal .accordion-item h2 {
  border: 0;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.SearchHistoryModal .accordion-button:not(.collapsed) {
  box-shadow: none;
}
.SearchHistoryModal .accordion-body {
  padding: 20px 0;
  font-size: 14px;
  /* border-bottom: 1px solid #000; */
  padding-top: 0;
}
.SearchHistoryModal .accordion-button:focus {
  box-shadow: none;
}
.SearchHistoryModal .accordion-item {
  border-bottom: 1px solid #000;
  border-radius: 0;
}
.sHaBody {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  color: #868a8e;
  font-size: 14px;
}
.sHaBody > li:first-child {
  width: 30%;
}
.sHaBody > li:nth-child(2) {
  width: 40%;
}
.sHaBody > li:last-child {
  width: 30%;
}
.sHaBody span {
  color: #2d2d2d;
}
.sHaBody .innerPart li {
  margin: 10px 0;
}
.sHaBody .innerPart .logoContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-weight: 500;
  color: #2d2d2d;
  font-size: 14px;
}

.report3Clo ul {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px;
}
.scribe_count {
  width: 40%;
}
.mpnth_search {
  width: 32%;
}
.product_search {
  width: 28%;
}
.report3Clo ul {
}
.report3Clo ul li {
  /* border: 1px solid red; */
}
.whiteBoxShadow {
  background-color: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 25px;
}
.report3Clo ul .whiteBoxShadow {
  min-height: 440px;
}
.whiteBoxShadow h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.ReportDate {
  display: flex;
  gap: 10px;
}
.ReportDate .search_icon.input-group .form-control {
  background-color: transparent !important;
  font-size: 10px;
  color: #868a8e;
  padding: 8px 30px 8px 10px;
}
.ReportDate .dateButtonContainer {
  width: 150px;
}
.ReportDate .search_icon.input-group .dateButton,
.ReportDate .search_icon.input-group .dateButton:hover {
  display: flex;
  top: 3px;
  right: 4px;
}
.report_right_button {
  justify-content: flex-end;
  display: flex;
  width: 100%;
}

.paymentThreeBox {
  display: flex;
  gap: 20px;
  margin: 30px 0 50px 0;
}
.paymentThreeBox > .whiteBoxShadow {
  width: 280px;
}
.smallGrayText {
  font-size: 16px;
  color: #868a8e;
  font-weight: 600;
  margin-bottom: 10px;
}
.bigBlueText {
  font-size: 32px;
  color: #1c428b;
  font-weight: 600;
}

.AdminDashboardFourBox ul {
  display: flex;
  gap: 25px;
}
.AdminDashboardFourBox ul li {
  width: 25%;
}
.AdminDashboardFourBox .AdminDashboardFourUL {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.AdminDashboardFourBox .twoPartCommon {
  align-items: center;
}
.AdminDashboardFourBox .AdminDashboardFourUL li {
  width: 100%;
}
.AdminDashboardFourBoxHead {
  font-size: 16px;
  color: #868a8e;
  font-weight: 500;
}
.AdminDashboardFourBoxNumber {
  font-size: 32px;
  color: #2d2d2d;
  font-weight: 700;
}
.AdminDashboardThreeBox {
  display: flex;
  gap: 25px;
  margin-top: 25px;
}
.AdminDashboardThreeBox > .whiteBoxShadow {
  width: 33.33%;
}

.AgentRangePicker {
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 12px 15px;
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-picker
  .ant-picker-input
  > input:hover {
  border-color: #d2d2d2;
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-picker-focused.ant-picker {
  border-color: #d2d2d2;
  box-shadow: none;
  outline: 0;
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-picker:hover {
  border-color: #d2d2d2;
}

/* agent menu start */
.mainMenu .AgentDashboard a {
  background: url(/public/images/agent_dashboard_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .AgentDashboard a.active,
.mainMenu .AgentDashboard a:hover {
  background: url(/public/images/agent_dashboard_active.svg) no-repeat 0px 50%;
}

.mainMenu .AgentSearchHistory a {
  background: url(/public/images/agent_search_history_deactive.svg) no-repeat
    0px 50%;
}
.mainMenu .AgentSearchHistory a.active,
.mainMenu .AgentSearchHistory a:hover {
  background: url(/public/images/agent_search_history_active.svg) no-repeat 0px
    50%;
}

.mainMenu .AgentCarriers a {
  background: url(/public/images/agent_carriers_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .AgentCarriers a.active,
.mainMenu .AgentCarriers a:hover {
  background: url(/public/images/agent_carriers_active.svg) no-repeat 0px 50%;
}

.mainMenu .AgentUserManagement a {
  background: url(/public/images/agent_user_management_deactive.svg) no-repeat
    0px 50%;
}
.mainMenu .AgentUserManagement a.active,
.mainMenu .AgentUserManagement a:hover {
  background: url(/public/images/agent_user_management_active.svg) no-repeat 0px
    50%;
}

.mainMenu .AgentReport a {
  background: url(/public/images/agent_report_deactive.svg) no-repeat 0px 50%;
}
.mainMenu .AgentReport a.active,
.mainMenu .AgentReport a:hover {
  background: url(/public/images/agent_report_active.svg) no-repeat 0px 50%;
}

.mainMenu .AgentBillingManagement a {
  background: url(/public/images/agent_billing_management_deactive.svg)
    no-repeat 0px 50%;
}
.mainMenu .AgentBillingManagement a.active,
.mainMenu .AgentBillingManagement a:hover {
  background: url(/public/images/agent_billing_management_active.svg) no-repeat
    0px 50%;
}


/* Edit Reviews */

.mainMenu .AgentReviews a {
  background: url(/public/images/agent_billing_management_deactive.svg)
    no-repeat 0px 50%;
}
.mainMenu .AgentReviews a.active,
.mainMenu .AgentReviews a:hover {
  background: url(/public/images/agent_billing_management_active.svg) no-repeat
    0px 50%;
}
/* Edit Reviews */


.cc_icons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.cc_icons_box {
  border-radius: 5px;
  border: 1px solid #d8e5f0;
  width: 48.5%;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
}

/* agent menu end */

.AgentDashLeftRight .white_data_box {
  /* height: 100%; */
  /* padding-bottom: 0; */
}
.adminDasHead {
  margin-top: 35px;
}

/* custom switch start */
.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}
/* .switch-item {display: block;} */
.switch-item {
  display: flex;
}
.switch-item .label {
  margin-left: 7px;
  vertical-align: middle;
  font-size: 13px;
  color: #000;
  line-height: 28px;
  font-weight: 500;
}
.switch-item .label_L {
  margin-right: 7px;
  vertical-align: middle;
  font-size: 13px;
  color: #000;
  line-height: 28px;
  font-weight: 500;
}
/* .switch-item .control[type='checkbox'] {appearance: none; position: relative; width: 60px; height: 30px; padding: 3px; border-radius: 5px; background-color: #ebebeb; vertical-align: middle; transition: all .3s cubic-bezier(.4, 0, .1, 1.4);} */
.switch-item .control[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 60px;
  height: 30px;
  padding: 3px;
  border-radius: 5px;
  background-color: #ebebeb;
  background: #ebebeb url(/public/images/swith_bg_img.png) no-repeat center
    center;
  vertical-align: middle;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.1, 1.4);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.switch-item .control[type="checkbox"]:after {
  content: "";
  position: relative;
  display: block;
  left: 0;
  width: 60.9%;
  height: 100%;
  border-radius: 4px;
  /* background-color: #fff;  */
  background: #fff url(/public/images/switch_three_line.png) no-repeat center
    center;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.3s ease, margin 0.3s ease;
  box-sizing: content-box;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.switch-item .control[type="checkbox"]:active:after {
  padding-right: 8px;
}
.switch-item .control[type="checkbox"]:checked {
  background-color: #3aa93d;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}
.switch-item .control[type="checkbox"]:checked:after {
  left: 39.1%;
}
.switch-item .control[type="checkbox"]:checked:active:after {
  margin-left: -8px;
}

.switch-item .control[type="checkbox"] ~ .label:before {
  content: "Inactive";
  color: #000;
}
.switch-item .control[type="checkbox"]:checked ~ .label:before {
  content: "Inactive";
  color: #868a8e;
}

.switch-item .control[type="checkbox"] ~ .label_L::before {
  content: "Active";
  color: #868a8e;
}
.switch-item .control[type="checkbox"]:checked ~ .label_L::before {
  content: "Active";
  color: #000;
}

.switch-item .control[type="checkbox"]:disabled {
  opacity: 0.3;
}
.switch-item .control[type="checkbox"]:disabled:active:after {
  padding-right: 0;
  margin-left: 0;
}

.control {
  order: 2;
}
.label_L {
  order: 1;
}
.label {
  order: 3;
}

/* custom switch end */

.AgentDashLeftRight .topPart {
  margin-bottom: 16px;
}
.AgentDashLeftRight .heading02 {
  margin-bottom: 28px;
}
.AgentDashRight .orangeActiveDeactive {
  margin-bottom: 26px;
}
.white_data_box2-Wrapper {
  margin-top: 15px;
}
.AgentDashLeftRight .white_data_box2 {
  margin-bottom: 10px;
}
.AgentDashRightContainer {
  padding: 0 0 0 30px;
  margin-top: 30px;
}
.thumb_icon_like,
.thumb_icon_like:hover {
  background: #fff url(/public/images/thumb_like_normal.svg) no-repeat 0px 50%;
  width: 20px;
  height: 20px;
  border: 0;
}
.thumb_icon_like.active,
.thumb_icon_like.active:hover {
  background: #fff url(/public/images/thumb_like_active.svg) no-repeat 0px 50%;
  width: 20px;
  height: 20px;
  border: 0;
}
.thumb_icon_dislike,
.thumb_icon_dislike:hover,
:not(.btn-check) + .thumb_icon_dislike.btn:active {
  background: #fff url(/public/images/thumb_dislike_normal.svg) no-repeat 0px
    50%;
  width: 20px;
  height: 20px;
  border: 0;
  display: inline-flex;
  padding: 0;
}
.thumb_icon_dislike.active,
.thumb_icon_dislike.active:hover,
:not(.btn-check) + .thumb_icon_dislike.active.btn:active {
  background: #fff url(/public/images/thumb_dislike_active.svg) no-repeat 0px
    50%;
  width: 20px;
  height: 20px;
  border: 0;
}
.logo_like_button {
  display: flex;
  align-items: center;
  gap: 25px;
}
.white_data_box2-Wrapper .white_data_box2:last-child {
  margin-bottom: 0;
}
.LikeModal .modal-header {
  border-bottom: 0;
}
.LikeModal .modal-body {
  padding: 0 30px 30px 30px;
}
.LikeModal ul {
  display: flex;
  align-items: center;
}
.LikeModal span {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
}
.LikeModal p {
  font-size: 14px;
  color: #2d2d2d;
  font-weight: 400;
}
/* .LikeModal label {
  padding: 0 10px 0 0;
} */
.disLikeModal .modal-header {
  border-bottom: 0;
}
.disLikeModal .modal-body {
  padding: 0 30px 30px 30px;
}
.disLikeModal .imageArea {
  text-align: center;
  margin-bottom: 10px;
}
.disLikeModal .headingArea {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 25px;
}
.disLikeModal .subHeadArea {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}
.greenBoxModal {
  border-radius: 5px;
  padding: 14px;
  background: #e4f5d8 url(/public/images/greenTick.svg) no-repeat right 10px
    center;
  margin: 12px 0;
  font-size: 12px;
  font-weight: 600;
  color: #2d2d2d;
}
.redBoxModal {
  border-radius: 5px;
  padding: 14px;
  background: #ffeaea url(/public/images/redCross.svg) no-repeat right 10px
    center;
  margin: 12px 0;
  font-size: 12px;
  font-weight: 600;
  color: #2d2d2d;
}

/* orange custom Radio start */
.orangeRadio [type="radio"]:checked,
.orangeRadio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.orangeRadio [type="radio"]:checked + label,
.orangeRadio [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.orangeRadio [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.orangeRadio [type="radio"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ff9900;
  border-radius: 100%;
  background: #fff;
}
.orangeRadio [type="radio"]:checked + label:after,
.orangeRadio [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #ff9900;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.orangeRadio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.orangeRadio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
/* orange custom Radio end */

.SubscriptionAgentBorder {
  width: 320px;
  border: 1px solid #d2d2d2;
  padding: 38px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  text-align: center;
}
.SubscriptionAgentBorder h3 {
  font-size: 20px;
  color: #2d2d2d;
  font-weight: 700;
  margin-bottom: 30px;
}
.SubscriptionAgentBorder .purpleBox {
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  margin-bottom: 28px;
}
.SubscriptionAgentBorder .purpleBox h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}
.SubscriptionAgentBorder .purpleBox p {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}
.SubscriptionAgentBorder h5 {
  color: #1c428b;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}
.SubscriptionAgentBorder p {
  font-size: 13px;
  color: #5f5f5f;
  font-weight: 400;
}
.SubscriptionAgentBorder p.smallFont {
  font-size: 10px;
  color: #5f5f5f;
  font-weight: 400;
  margin-top: 20px;
  padding: 0 15px;
}
.SubscriptionAgentBorder p span {
  color: #1c428b;
}
.SubscriptionAgentBorder button {
  font-size: 14px;
  font-weight: 600;
}
.carriers_item_name {
  text-align: center;
  color: #2d2d2d;
  font-size: 12px;
  font-weight: 400;
}
.carriersAgentPage .carriers_item ul li .image_container {
  height: 148px;
}
.carriers_item_switch .form-check-input:checked[type="checkbox"] {
  background-color: #ff8946;
  border-color: #ff8946;
}
.carriers_item_switch .form-switch .form-check-input {
  background-color: #cacaca;
  border-color: #cacaca;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}
.carriers_item_switch
  .form-switch
  .form-check-input:checked[type="checkbox"]:focus {
  border-color: #ff8946;
}
.carriers_item_switch .form-switch .form-check-input:focus {
  border-color: #cacaca;
}
.carriers_item_switch .form-check.form-switch {
  display: flex;
  justify-content: flex-end;
}
.carriers_item_switch input {
  cursor: pointer;
}

.priorityClear_list_modal .modal-content {
  background-color: #ecf3f9;
}
.priorityClear_list_modal .cursorPointer {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 222;
}
.priorityClear_list_modal .left.heading02 {
  width: 100%;
  text-align: center;
}
.priorityClear_list_modal .box {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.07);
  padding: 15px;
  align-items: center;
  margin-bottom: 5px;
}

.walletBlanceBox {
  background: #94d8e7;
  border-radius: 10px;
  padding: 25px;
  color: #2d2d2d;
  min-height: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.walletBlanceTwoBox {
  margin-top: 30px;
  display: flex;
  gap: 15px;
}
.walletBlanceTwoBox .walletBlanceBox {
  width: 270px;
}
.walletBlanceTwoBox .whiteBoxShadow {
  width: calc(100% - 270px);
}
.downloadLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #02a1c9;
  gap: 5px;
}
.walletBlanceBox h2 {
  font-size: 16px;
  font-weight: 600;
}
.walletBlanceBox p {
  font-size: 24px;
  font-weight: 700;
}
.walletBlanceTwoBox h2 {
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 600;
}
.walletBlanceCardDetails {
  display: flex;
  align-items: center;
  gap: 20px;
}
.walletBlanceTwoBox > .twoPartCommon {
  align-items: center;
  justify-content: center;
}
.walletBlanceCardDetails ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.walletBlanceCardDetails ul li:first-child {
  font-size: 18px;
  color: #868a8e;
  font-weight: 400;
}
.walletBlanceCardDetails ul li:first-child span {
  color: #2d2d2d;
  font-weight: 500;
}
.walletBlanceCardDetails ul li.cardNumber {
  font-size: 13px;
  color: #868a8e;
  font-weight: 400;
}
.walletBlanceTwoBox .whiteBoxShadow .right {
  align-items: center;
}
.walletBlanceCardDetails ul li.cardNumber span {
  margin-right: 40px;
}
.walletBlanceCardDetails ul li.cardNumber span:last-child {
  margin-right: 0px;
}
.walletBlanceTwoBox .whiteBoxShadow .right a {
  text-decoration: none;
}
.orangeActiveDeactiveContainer {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
}
.orangeActiveDeactiveContainer span {
  color: #868a8e;
  font-size: 13px;
  font-weight: 600;
}
.PaymentCardPayment {
  margin-top: 30px;
}
.PaymentCardPayment label {
  color: #868a8e;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}
.PaymentCardPayment .row {
  margin-bottom: 20px;
}
.InputGroupCVV button,
:not(.btn-check) + .InputGroupCVV .btn:active {
  position: absolute !important;
  border: 0;
  right: 0;
  top: 0px;
  padding: 14px 5px;
  height: 100%;
  z-index: 9 !important;
}
.InputGroupCVV button:hover,
.InputGroupCVV .btn:focus-visible,
:not(.btn-check) + .InputGroupCVV .btn:active:focus-visible {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.InputGroupCVV input.form-control {
  border-left: 1px solid #d2d2d2 !important;
  padding: 12px 15px;
}
.InputGroupCVV input.form-control:focus,
.InputGroupCVV input.form-control:active {
  border-left: 1px solid #02a1c9 !important;
}
.twoButtonSideBySide {
  display: flex;
  align-items: center;
  gap: 10px;
}
.twoButtonSideBySide2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 8px;
}
.profile_area {
  border: 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}
.profile_area .dropdown-item {
  font-size: 12px;
  color: #2d2d2d;
  line-height: 22px;
}
.profile_area .dropdown-item:hover,
.profile_area .dropdown-item:active,
.profile_area .dropdown-item:focus {
  background-color: #fff;
}
.profile_image_area,
.reset_password_area {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding-top: 50px;
}
.profile_image_area p {
  font-weight: 700;
  font-size: 16px;
}
.reset_password_area p {
  font-weight: 700;
  font-size: 18px;
}
reset_password_area .profile_image_area label,
.reset_password_area label {
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 400;
}
.profile_image_area textarea {
  background-color: #f5f5f5;
  border: 1px solid #d4d4d4;
  color: #2d2d2d;
  font-size: 12px;
}
.profile_image_area_box {
  position: relative;
}
.profile_image_area_edit {
  position: absolute;
  bottom: 22px;
  right: 22px;
  background: #fff;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.logout_modal p {
  font-size: 16px;
  color: #2d2d2d;
  font-weight: 500;
  text-align: center;
  line-height: 21px;
}
.newStateModal h3 {
  font-weight: 600;
  font-size: 18px;
}
.SelectedState .newStateModalOne .checkbox-label {
  color: #02a1c9;
  transition: 0.375s ease;
  text-align: center;
  font-size: 14px;
}
.SelectedState
  .newStateModalOne
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile {
  border-color: #02a1c9;
  color: #fff;
  background-color: #02a1c9;
}
.SelectedState
  .newStateModalOne
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #fff;
}
.SelectedState
  .newStateModalTwo
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile {
  border-color: #02a1c9;
  color: #fff;
  background-color: #02a1c9;
}
.SelectedState
  .newStateModalTwo
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #fff;
}
.image_container img {
  /* max-width: 154px; */
  max-height: 104px;
}

.activeInactive.borderBoxCheckBox
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile {
  border-color: #b8ebc6;
  box-shadow: none;
  color: #0b6c26;
  background: #b8ebc6;
}
.activeInactive.borderBoxCheckBox .checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  min-height: 36px;
  border-radius: 3px;
  border: 1px solid #e7e7e7;
  background-color: #d2d2d2;
  box-shadow: none;
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  padding: 0 14px;
}
.activeInactive.borderBoxCheckBox .checkbox-label {
  color: #818181;
  transition: 0.375s ease;
  text-align: center;
  font-size: 12px;
}
.borderBoxCheckBox
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #0b6c26;
}
.logoutText {
  font-size: 16px;
  color: #2d2d2d;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}
.logoutButton {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

/* common */
.circle_icon_container {
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 100px;
  position: absolute;
  padding: 5px;
  top: 5px;
  right: 5px;
  /* box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; */
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  z-index: 9;
  cursor: pointer;
}
.circle_icon_container_li {
  position: relative;
}
.button_grayBorder,
.button_grayBorder:hover,
.button_grayBorder.btn:first-child:active,
.button_grayBorder.btn:focus-visible {
  border-radius: 4px;
  border: 1px solid #c9c9c9;
  background: #fff;
  background: transparent;
  color: #868a8e;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  display: flex;
  padding: 9px 24px;
  font-size: 14px;
  box-shadow: none;
}

.button_lightBlueFill,
.button_lightBlueFill:hover,
.button_lightBlueFill.btn:first-child:active,
.button_lightBlueFill.btn:focus-visible {
  border-radius: 4px;
  border: 1px solid #02a1c9;
  background: #fff;
  background: #02a1c9;
  color: #fff;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  display: flex;
  padding: 9px 24px;
  font-size: 14px;
  box-shadow: none;
}
.imageColorWhite {
  filter: brightness(0) invert(1);
}
.cursorPointer {
  cursor: pointer;
}
.darkBlueButton,
.darkBlueButton:hover,
.darkBlueButton.btn:first-child:active,
.darkBlueButton.btn:focus-visible {
  background: #1c428b;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  padding: 10px 25px;
  border: none;
  color: #fff;
  box-shadow: none;
}
.bgTransparent,
.bgTransparent:hover {
  background: transparent;
}
.devider_01 {
  color: #d4d4d4;
  padding: 0 10px;
}
.devider_02 {
  border-top: 1px solid #bad0e4;
  margin-top: 26px;
  padding-top: 26px;
}
.heading01 {
  font-size: 14px;
  font-weight: 500;
}
.heading02 {
  font-size: 18px;
  font-weight: 600;
}
.heading03 {
  font-size: 16px;
  font-weight: 600;
}
.rightWithButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.tdWidth100 {
  width: 100px;
}
.tdCenter {
  text-align: center;
}
.redBorderButton,
.redBorderButton:hover,
.redBorderButton:focus,
.redBorderButton:active,
.btn-check.redBorderButton:checked + .btn,
.btn.redBorderButton.active,
.btn.show,
.btn.redBorderButton:first-child:active,
:not(.btn-check) + .redBorderButton.btn:active {
  border: 1px solid #f4392d;
  color: #f4392d;
  font-size: 13px;
  font-weight: 500;
  border-radius: 6px;
  padding: 12px 40px 12px 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blankContainer {
  margin-top: 20px;
}
.white_data_box {
  background-color: #fff;
  margin-top: 30px;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding-bottom: 50px;
  padding: 30px;
}
.white_data_box2 {
  background-color: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 12px 10px;
}
.white_data_box h2 {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.white_data_box h2 span.lightText {
  color: #868a8e;
  font-weight: 400;
}
.active_green {
  background: #b8ebc6;
  color: #0b6c26;
  font-size: 12px;
  padding: 4px 9px 4px 9px;
  border-radius: 3px;
  font-weight: 400;
}
.pending_gray {
  background: #e9e9e9;
  color: #7d7d7d;
  font-size: 12px;
  padding: 4px 9px 4px 9px;
  border-radius: 3px;
  font-weight: 400;
}
.pending_red {
  background: #ffd6d6;
  color: #dd4040;
  font-size: 12px;
  padding: 4px 9px 4px 9px;
  border-radius: 3px;
  font-weight: 400;
}
.search_icon.input-group {
  position: relative;
}
.search_icon.input-group .form-control {
  border-left: 1px solid #d2d2d2;
  padding-left: 1px solid #d2d2d2;
  padding: 12px 35px 12px 15px;
}
.search_icon.input-group button:hover,
.search_icon.input-group button {
  background-color: transparent;
  border-color: 1px solid #d2d2d2 !important;
  border: transparent;
  padding: 0;
  right: 2px;
  top: 5px;
  position: absolute;
  padding: 5px;
  z-index: 22;
}

.mt_200 {
  margin-top: 200px;
}
.mt_30 {
  margin-top: 30px;
}
.opacity07 {
  opacity: 0.7;
}
.colorBlue {
  color: #1c428b;
}
.colorBlueLink,
.colorBlueLink:hover {
  color: #1c428b;
  text-decoration: none;
}
.colorBlack {
  color: #000;
}
.colorDarkGray {
  color: #2d2d2d;
}
.hand {
  cursor: pointer;
}
.pad20 {
  padding: 20px;
}
.input70 {
  width: 70px;
}
.modalCrossNormal {
  position: absolute;
  top: 22px;
  right: 22px;
}
.shadowBox330 {
  width: 330px;
  background-color: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 35px;
}
.LeftRightPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sideBySideBoxLeftRight {
  display: flex;
  align-items: center;
  gap: 20px;
}
.sideBySideBoxLeftRight .left {
  width: 50%;
}

.sideBySideBoxLeftRight .right {
  width: 50%;
}
.sideBySideBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.sideBySideBox .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.borer_b_E4E4E4 {
  border-bottom: 1px solid #e4e4e4;
}
.sideBySideBox .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headText {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 34px;
}
/* two colom side by side  */
.two_50_50 li {
  width: 50%;
}
.two_50_50 {
  gap: 20px;
}
.sideBySideTextBox {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.twoPartCommon {
  display: flex;
  justify-content: space-between;
}
.twoPartCommon .left .status {
  font-size: 13px;
  font-weight: 600;
  color: #2d2d2d;
  opacity: 0.7;
}
.twoPartCommon .right {
  display: flex;
  gap: 10px;
}
.twoPartCommon .right .Active {
  background: #b8ebc6;
  color: #0b6c26;
  border-radius: 3px;
  padding: 5px;
  font-size: 12px;
}
.twoPartCommon .right .Inactive {
  background: #d2d2d2;
  color: #818181;
  border-radius: 3px;
  padding: 5px 15px;
  font-size: 12px;
}

/* .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
} */
/* .form-control:focus {
  border: 1px solid #d2d2d2;
} */
.form-control:focus,
.form-control:active {
  border: 1px solid #02a1c9;
}
.input-group-text {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
}
.input-group .form-control {
  /* border-left: 0; */
}

.form_control_2 {
  padding: 11px 14px;
  font-size: 12px;
}
.width125 {
  width: 125px;
}
.width180 {
  width: 180px;
}
.sidebar-toggle .full_logo {
  display: none;
}
.small_logo {
  display: none;
}
.sidebar-toggle .small_logo {
  display: flex;
  padding-bottom: 4px;
}
.form-control:focus {
  box-shadow: none;
}
.light_gray_text {
  color: #868a8e;
  text-decoration: none;
}
.sideBySide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-check-input {
  border-color: #868a8e;
}
.form-check-input:focus {
  box-shadow: none;
  border-color: #868a8e;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}

/* Underwriting Guidelines page  start */

.unerGuide .heading {
  padding: 100px;
  padding-bottom: 50px;
  font-weight: 600;
  color: #000;
}
.unerGuide .heading h3 {
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-bottom: 22px;
}
.unerGuide .headingBottomText {
  color: #868a8e;
  font-size: 12px;
  font-weight: 500;
  /* text-align: center; */
  margin-bottom: 10px;
}
.unerGuide .headText {
  margin-bottom: 22px;
}
.Container357 {
  width: 357px;
  margin: 0 auto;
}
.underGuideTab.nav-tabs {
  border-bottom: 0;
}
.underGuideTab.nav-tabs .nav-link.active {
  background-color: #f90;
  /* border: 0; */
  color: #fff;
  border: 1px solid #f90;
  border-radius: 4px;
  /* padding: 16px 36px; */
  font-size: 12px;
  font-weight: 500;
  width: 110px;
  height: 46px;
}
.underGuideTab.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: 1px solid #cbd0d6;
  border-radius: 4px;
  /* padding: 16px 36px; */
  font-size: 12px;
  font-weight: 500;
  color: #868a8e;
  width: 110px;
  height: 46px;
}
.underGuideTab.width-auto-button.nav-tabs .nav-link.active {
  width: auto;
}
.underGuideTab.width-auto-button.nav-tabs .nav-link {
  width: auto;
}
.AdminDashboardWhiteBody ul.underGuideTab {
  display: flex;
  justify-content: flex-start;
  gap: 9px;
}
.SelectedState {
  display: flex;
  /* align-items: center; */
  /* gap: 17px; */
  margin-bottom: 20px;
}
.SelectedStateLabel {
  color: #868a8e;
  font-size: 12px;
  width: 80px;
  padding-right: 10px;
  /* border: 1px solid black; */
  font-weight: 500;
}
.Container357 .formBox01 .topPart div {
  display: flex;
  gap: 15px;
}
.Container357 .formBox01 .topPart {
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin-bottom: 16px;
}
.Container357 .formBox01 .bottomPart {
  display: flex;
  gap: 6px;
}
.Container357 .formBox01 .topPart h2 {
  font-size: 14px;
  font-weight: 500;
}
.formBox01 .twoPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formBox01.noBorder {
  border: 0;
  padding: 0;
  margin-top: 20px;
}

.noBorder .twoPart .left .heading {
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 600;
}
.noBorder .twoPart .left .smallTest {
  color: #868a8e;
  font-size: 10px;
  font-weight: 600;
}

.upload_btn_wrapper input[type="file"] {
  width: 120px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #02a1c9;
}

.upload_btn_wrapper input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #fff;
  padding: 5px 20px;
  border-radius: 4px;
  color: #02a1c9;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  font-size: 13px;
  font-weight: 600;
}

.upload_btn_wrapper input[type="file"]::file-selector-button:hover {
  background: #fff;
}

.noBorder .left .head {
  color: #2d2d2d;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 600;
}

/* custom check box border box start */
.SelectedState .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* max-width: 600px; */
  user-select: none;
  gap: 6px;
}
.SelectedState .checkbox-group > * {
  margin: 0;
}
.SelectedState .checkbox-group-legend {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9c9c9c;
  text-align: center;
  line-height: 1.125;
  margin-bottom: 1.25rem;
}
.SelectedState .checkbox-input {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.SelectedState .checkbox-input[type="checkbox"]:checked + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #fff;
  background-color: #02a1c9;
}
.SelectedState .checkbox-input[type="radio"]:checked + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #02a1c9;
}
.SelectedStateRadio.SelectedState
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #fff;
  background-color: #02a1c9;
}
.plus_blue {
  border-color: #1c428b !important;
  background-color: #1c428b !important;
  width: 22px !important;
  height: 22px !important;
  min-height: 22px !important;
  border-radius: 100px !important;
  padding: 0 !important  ;
  color: #fff !important;
}
.plus_blue .checkbox-label {
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 500;
}
.SelectedState
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #02a1c9;
}
.SelectedStateRadio.SelectedState
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #fff;
}
.SelectedState .checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #74ba43;
  border-color: #74ba43;
}
.SelectedState
  .checkbox-input[type="checkbox"]:checked
  + .checkbox-tile
  .checkbox-icon,
.SelectedState
  .checkbox-input[type="checkbox"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #fff;
}

.SelectedState .checkbox-input:focus + .checkbox-tile {
  border-color: #e7e7e7;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc; */
}
.SelectedState .checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}
.SelectedState .checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  min-height: 36px;
  border-radius: 3px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  padding: 0 14px;
  width: 50px;
}
.SelectedState .checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 13px;
  height: 13px;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: -7px;
  right: -7px;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: none;
}
.SelectedState .checkbox-tile:hover {
  border-color: #02a1c9;
}
.SelectedState .checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}
.SelectedState .checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
  padding-top: 5px;
}
.SelectedState .checkbox-icon img {
  width: 25px;
  height: 24px;
}
.SelectedState .checkbox-label {
  color: #868a8e;
  transition: 0.375s ease;
  text-align: center;
  font-size: 14px;
}
.SelectedState .checkbox-group .checkbox:first-child {
  margin-left: 0;
}
.SelectedState .checkbox-group .checkbox:last-child {
  margin-right: 0;
}
.SelectedState .checkbox-input:checked + .checkbox-tile > .checkbox-icon {
  opacity: 0.5;
}
.bottomPagincation {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottomPagincation .active > .page-link,
.page-link.active {
  background-color: #02a1c9;
  border-color: #02a1c9;
}
.bottomPagincation .page-link {
  color: #02a1c9;
  box-shadow: none;
}
.bottomPagincation .active .page-link {
  color: #fff;
  box-shadow: none;
}
.bottomPagincation .page-link,
.bottomPagincation .page-link:hover,
.bottomPagincation .page-link:focus {
  box-shadow: none;
}
.bottomPagincation .totalNumber {
  color: #868a8e;
  font-weight: 400;
  font-size: 14px;
}
.profile_image_area_edit_button,
.profile_image_area_edit_button:hover,
.profile_image_area_edit_button.btn:first-child:active {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.profile_image_area_edit_fileUpload {
  display: none;
}
/* custom check box with border box end #02a1c9 */

/* Underwriting Guidelines page  end */

/* main site css start */
.desktop_show_tab_off {
  display: flex;
}
.headerMainContainer .mainMenu.desktop_show_tab_off {
  display: flex;
}
.desktop_off_tab_show {
  display: none;
}
.tab_show_mobile_off {
  display: flex;
}

.desktop_off_mobile_show {
  display: none;
}
.desktop_show_mobile_off {
  display: flex;
}

.headerMainContainerOuter {
  background: #d7e7fa;
}
.headerMainContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 90px;
}
.Risker_final_logo {
  width: 181px;
}
.headerMainContainer .left {
  display: flex;
  gap: 80px;
}
.headerMainContainer .right {
  display: flex;
  gap: 10px;
  align-items: center;
}
.headerMainContainer .mainMenu {
  display: flex;
  gap: 40px;
  font-size: 17px;
  font-weight: 600;
}
.headerMainContainer .right .phone {
  display: flex;
  align-items: center;
  gap: 9px;
  color: #1c428b;
  font-size: 16px;
  font-weight: 700;
}
.login_main_header,
.login_main_header:hover {
  border: 1px solid #02a1c9;
  font-size: 17px;
  font-weight: 700;
  color: #02a1c9;
  border-radius: 6px;
  padding: 13px 30px;
  background-color: transparent;
}
.headerMainContainer .mainMenu a {
  padding: 0;
  font-size: 17px;
  font-weight: 600;
  height: auto;
}
.headerMainContainer .mainMenu a.active::after,
.headerMainContainer .mainMenu a:hover::after {
  display: none;
}
.trial_main_header,
.trial_main_header:hover {
  border: 1px solid #1c428b;
  font-size: 17px;
  font-weight: 700;
  color: #fff;
  border-radius: 6px;
  padding: 13px 30px;
  background-color: #1c428b;
}
.mainBanner {
  /* height: 680px; */
  /* background-image:  url(../../../public/images/main/main_banner_home.svg) ; */
  background-color: #d7e7fa;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.mainBanner img {
  width: 100%;
  margin-top: 90px;
}
.mainBannerContainer {
  position: absolute;
}
.mainBannerContainer h1 {
  /* font-size: 7.5vh; */
  font-size: 67px;
  font-family: "Hanuman", serif;
  width: 570px;
  font-weight: 700;
  /* line-height: 8vh; */
  line-height: 67px;
}
.mainBannerContainer p {
  width: 35%;
  margin-top: 20px;
}
.book_a_demo_content_inner {
  padding: 100px;
}
.book_a_demo_content_inner .orange_circle {
  width: 84px;
  height: 84px;
  background: #ff9900;
  display: flex;
  text-align: center;
  justify-content: center;
  color: #fff;
  align-items: center;
  border-radius: 100px;
  font-size: 50px;
  font-weight: 400;
  font-family: "Hanuman", serif;
}
.book_a_demo_content_ul {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 50px;
}
.book_a_demo_content_ul li:first-child {
  padding-right: 30px;
}
.book_a_demo_content_ul_1st {
  position: relative;
}
.book_a_demo_content_ul_1st::after {
  content: "";
  width: 1px;
  height: calc(100% - 100px);
  top: 100px;
  background: #868a8e;
  position: absolute;
  left: 42px;
}
.book_a_demo_content_ul_1st:last-child:after {
  display: none;
}
.book_a_demo_content_ul h3 {
  font-size: 45px;
  line-height: 50px;
  font-family: "Hanuman", serif;
  margin-bottom: 20px;
}
.book_a_demo_content_ul_bullete {
  width: 100%;
}
.book_a_demo_content_ul_bullete li {
  background: url(../../../public/images/main/book_a_demo_content_ul_bullete.svg)
    no-repeat left 9px;
  padding-left: 20px;
  font-size: 16px;
  color: #2d2d2d;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 20px;
}
.grayBox {
  background: #e0e7ef;
  padding: 100px;
}
.grayBox .button_container button {
  color: #fff;
  background: #02a1c9;
  padding: 20px 60px;
  font-size: 16px;
  font-weight: 600;
}
.button_container {
  text-align: right;
}

.Book_a_Demo,
.Book_a_Demo:hover,
.Book_a_Demo:active,
.Book_a_Demo.btn.btn-primary:active {
  font-size: 20px;
  font-weight: 600;
  background-color: #02a1c9;
  border-color: #02a1c9;
  padding: 14px 35px;
  margin-top: 25px;
  color: #fff;
}
.hamburger_menu,
.hamburger_menu:hover {
  background: transparent;
  border: 0;
}
.button_menu_area {
  display: flex;
  gap: 10px;
  align-items: center;
}
.OurCoverages {
  text-align: center;
  padding-top: 150px;
  position: relative;
  margin-bottom: 130px;
}
.OurCoverages h2 {
  font-size: 63px;
  font-family: "Hanuman", serif;
}
.OurCoverages_4Ppart {
  display: flex;
  gap: 50px;
  margin-top: 70px;
}
.OurCoverages_4Ppart ul {
  width: 25%;
}
.OurCoverages_4Ppart li {
  /* align-items: center;
  justify-content: center;
  display: flex; */
  text-align: center;
}
.sponsorsLogo {
  position: absolute;
  width: 100%;
  height: 100px;
  top: -58px;
  padding: 0 150px;
}
.sponsorsLogo ul {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 16px 60px;
  border-radius: 100px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  align-items: center;
}
.sponsorsLogo ul img {
  width: 100%;
}
.OurCoverages p {
  font-size: 17px;
  color: #555555;
}
.OurCoverages_4Ppart .head {
  font-weight: 700;
  color: #131a2f;
  margin-top: 30px;
  font-size: 26px;
}
.OurCoverages_4Ppart .bodyText {
  font-size: 15px;
  color: #555;
  font-weight: 400;
  line-height: 21px;
  margin-top: 13px;
  padding: 0 20px;
}
.Discover_why {
  background: #1c428b;
  border-radius: 50px 0px 0px 0px;
  position: relative;
  overflow: hidden;
}
.Discover_why_content {
  display: flex;
}
.Discover_why_content .left {
  width: 550px;
  padding: 110px 0;
}
.Discover_why_content .right {
  width: calc(100% - 550px);
  background: #fff2e0;
  padding-left: 100px;
}
.Discover_why_content .left h2 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  font-family: "Hanuman", serif;
  padding-right: 100px;
}
.Discover_why_content .left {
  padding: 110px 0;
}
.Discover_why_swiper .swiper-slide {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;
  margin: 20px 0;
}
.Discover_why .swiper {
  position: absolute;
  width: calc(100% - 670px);
  top: 30%;
}
.Discover_why_outer {
  display: flex;
}
.Discover_why_left {
  width: 45%;
  background: #1c428b;
  border-radius: 50px 0px 0px 0px;
  display: flex;
  justify-content: flex-end;
}
.Discover_why_right {
  width: 55%;
  background: #fff2e0;
  display: flex;
  align-items: center;
  padding-left: 75px;
}
.Discover_why_left_content_area {
  width: 550px;
  padding-right: 100px;
  margin-top: 100px;
  margin-bottom: 100px;
  position: relative;
}
.Discover_why_left_content_area h2 {
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  font-family: "Hanuman", serif;
  line-height: 60px;
  margin-bottom: 20px;
}
.Discover_why_left_content_area h2::after {
  position: absolute;
  content: "";
  width: 139px;
  height: 118px;
  background: url(../../../public/images/fa_quote-right.svg) no-repeat;
  bottom: -75px;
  right: 30px;
}
.SwiperSlideContent {
  padding: 30px;
}
.SwiperSlideContent ul {
  display: flex;
  align-items: center;
  gap: 20px;
}
.SwiperSlideContent ul .name {
  color: #868a8e;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px;
}
.SwiperSlideContent ul .date {
  color: #868a8e;
  font-size: 13px;
  font-weight: 500;
}
.SwiperSlideContent .bodyText {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0 40px 0;
}
.SwiperSlideContent ul li div {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  width: 55px;
  height: 55px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SwiperSlideContent ul li div.purple {
  background: #8e76b5;
}
.SwiperSlideContent ul li div.green {
  background: #a4c190;
}
.SwiperSlideContent ul li div.blue {
  background: #8f96d6;
}
.Discover_why_right .swiper-pagination {
  display: flex;
}
.Discover_why_right .swiper-wrapper {
  margin-bottom: 50px;
}
.Discover_why_right .swiper-pagination-bullet-active {
  background: #02a1c9;
}
.Find_out_more,
.Find_out_more:hover,
.Find_out_more.btn-check:checked + .Find_out_more.btn,
.Find_out_more.btn.active,
.Find_out_more.btn.show,
.Find_out_more.btn:first-child:active,
:not(.btn-check) + .Find_out_more.btn:active {
  font-size: 12px;
  background-color: #02a1c9;
  border-color: #02a1c9;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  padding: 14px 70px;
  border-radius: 8px;
}
.ReadAllReviews,
.ReadAllReviews:hover {
  font-size: 16px;
  border: 1px solid #02a1c9;
  background-color: transparent;
  padding: 16px 60px;
}

.map_area {
  padding: 100px 0;
}
.map_area ul {
  display: flex;
}
.map_area ul li {
  width: 50%;
}
.map_area .map_image {
  width: 100%;
}
.map_area_content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.map_area_content h3 {
  font-size: 65px;
  font-weight: 400;
  font-family: "Hanuman", serif;
}
.map_area_content p {
  font-size: 17px;
  font-weight: 400;
  color: #555;
  margin-bottom: 40px;
}
.map_area_content div {
  padding-left: 100px;
}

.how_it_works {
  background: #fff6e7;
  padding: 100px 0;
}
.how_it_works ul {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 35px 0;
}
.how_it_works ul li:first-child span {
  background: #f90;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.how_it_works h3 {
  font-size: 65px;
  color: #000;
  font-family: "Hanuman", serif;
  font-weight: 400;
}
.how_it_works p {
  font-size: 17px;
  color: #555;
}
.how_it_works ul li {
  position: relative;
}
.how_it_works ul li:first-child:after {
  content: "";
  width: 10px;
  height: 26px;
  background: url(../../../public/images/main/line_arrow.svg) no-repeat;
  position: absolute;
  bottom: -28px;
  left: 18px;
}
.how_it_works ul.last li:first-child:after {
  display: none;
}
.how_it_works .left {
  width: 50%;
  padding-right: 100px;
}
.how_it_works .right {
  width: 50%;
}
.how_it_works_content_area {
  display: flex;
}
.how_it_work_img {
  width: 100%;
}
.FooterMainContainer {
  background: linear-gradient(
    to right,
    #4b5156 0%,
    #4b5156 80%,
    #2d2d2d 80%,
    #2d2d2d 100%
  );
}
.FooterMainContainer_inner {
  display: flex;
}
.FooterMainContainer_inner > div:first-child {
  width: 75%;
  padding: 80px 70px 40px 0;
}
.FooterMainContainer_inner > div:last-child {
  width: 25%;
  background: #2d2d2d;
  padding: 80px 0 40px 0;
  color: #fff;
}
.FooterMainContainer_inner .left .FooterMainContainer_inner_content {
  display: flex;
  justify-content: space-between;
}
.FooterMainContainer_inner .left a {
  font-size: 18px;
  line-height: 38px;
  color: #dbdcdd;
  font-weight: 400;
  text-decoration: none;
}
.FooterMainContainer_inner .left .head {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}
.footer_bottom {
  border-top: 1px solid #000;
  margin-top: 100px;
  padding-top: 22px;
}
.footer_bottom {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 28px;
}
.footer_bottom span.devider {
  margin: 0 10px;
  width: 1px;
  background: #fff;
  display: inline-block;
}
.copyright {
  padding-left: 56px;
}
.FooterMainContainer_inner .right li {
  display: flex;
  gap: 10px;
  padding-left: 80px;
}
.FooterMainContainer_inner .right li:nth-child(2) {
  margin: 50px 0;
}
.FooterMainContainer_inner .right li:last-child {
  gap: 20px;
}

.innerBanner_01 {
  background-color: #d7e7fa;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.innerBanner_01 h1 {
  font-size: 67px;
  font-family: "Hanuman", serif;
  font-weight: 700;
  max-width: 44%;
  text-align: center;
  line-height: 74px;
  margin-top: 130px;
}
.innerBanner_01 > p {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: #555;
  margin: 30px 0 450px 0;
}
.innerBanner_bottom {
  padding: 100px 0;
}
.monthly_yearly_outer {
  position: relative;
}
.monthly_yearly {
  position: absolute;
  gap: 55px;
  margin: auto;
  top: -130px;
  left: 0;
  bottom: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  display: flex;
  text-align: center;
}
.monthly {
  width: 400px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.yearly {
  width: 400px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.monthly_yearly h3 {
  font-size: 58px;
  font-family: "Hanuman", serif;
  color: #fff;
  font-weight: 300;
}
.monthly_yearly .price {
  font-size: 68px;
  color: #fff;
  font-family: "Hanuman", serif;
  font-weight: 700;
}
.monthly_yearly .small_text {
  font-size: 22px;
  color: #fff;
  font-weight: 400;
}
.monthly_yearly span {
  font-size: 18px;
  color: #fff;
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
}
.monthly_yearly .monthly .top {
  background: #b467d8;
  padding: 60px 0 40px 0;
}
.monthly_yearly .yearly .top {
  background: #f88b68;
  padding: 60px 0 40px 0;
}
.monthly_yearly .bottom {
  padding: 40px;
}
.monthly_yearly .bottom ul li {
  text-align: left;
  font-size: 19px;
  color: #555;
  margin-bottom: 30px;
}
.monthly_yearly .bottom ul li img {
  margin-right: 5px;
}
.monthly_yearly .bottom button {
  background: #02a1c9;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  border-color: #02a1c9;
}
.help_you_box {
  border-radius: 20px;
  padding: 90px;
  color: #fff;
  margin-top: 250px;
  background: #1c428b
    url(../../../public/images/main/help_you_focus_box_background.svg) no-repeat
    right bottom;
}
.help_you_box h3 {
  font-size: 63px;
  line-height: 63px;
  font-weight: 300;
  font-family: "Hanuman", serif;
  width: 70%;
  margin-bottom: 20px;
}
.Find_a_Carriers,
.Find_a_Carriers:hover {
  border: 1px solid #fff;
  font-size: 20px;
  font-weight: 600;
  background-color: transparent;
  padding: 15px 60px;
  margin-top: 10px;
}

.main_login_bg {
  background: #d7e7fa url(/public/images/main/main_login_bg.svg) no-repeat
    bottom left -50px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  /* background-size: cover; */
}
.mainLogin_whiteBox {
  background: #fff;
  border-radius: 30px;
  width: 90%;
  box-shadow: 0px 8px 50px 0px rgba(116, 116, 116, 0.2);
}
.mainLogin_whiteBox_Inner {
  display: flex;
}
.mainLogin_whiteBox_Inner .left,
.mainLogin_whiteBox_Inner .right {
  width: 50%;
}
.mainLogin_whiteBox_Inner .left {
  background: #ecf3f9;
  padding: 50px;
  border-radius: 30px;
  text-align: center;
}
.whiteBox_outer {
  display: flex;
  justify-content: center;
}
.mainLogin_whiteBox h3 {
  font-family: "Hanuman", serif;
  font-weight: 400;
  font-size: 36px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 40px;
  margin-top: 50px;
}
.mainLogin_whiteBox .right .login_top {
  padding-top: 50px;
}
.mainLogin_whiteBox .right .register {
  margin-bottom: 50px;
}

.grayBox .row {
  margin-bottom: 25px;
}
.grayBox .row:last-child {
  margin-bottom: 0;
}
.grayBox .label_text {
  color: #2d2d2d;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.7;
}
.grayBox .form-control {
  color: #868a8e;
}
.grayBox .form-control::placeholder {
  color: #868a8e;
}
.grayBox .inputGroupButton {
  position: absolute;
  right: 0;
  top: 5px;
  border: 0;
  z-index: 5;
}
.grayBox .inputGroupButton:hover {
  background: transparent;
}
.carriersMainBg {
  /* background: url(../../../public/images/main/carriersMainBg.svg) no-repeat left -100px bottom -30px; */
  /* background-image: url(../../../public/images/main/carriersMainBg.svg); */
  background-repeat: no-repeat;
  /* min-height: 530px; */
  width: 100%;
  resize: none;
  object-fit: contain;
  /* background-size  : 100% 0; */
  /* background-size:cover; */
  /* background-position: left bottom -10px; */
}
.homeLink {
  display: flex;
  align-items: center;
}
.carriersMainBg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carriersMainBg img {
  width: 100%;
  top: 90px;
}
.carriersMainContainer {
  position: absolute;
}
.carriersMainBg h1 {
  font-size: 67px;
  font-family: "Hanuman", serif;
  width: 570px;
  font-weight: 700;
  line-height: 8vh;
  line-height: 67px;
}
.carriersMainContainer p {
  width: 75%;
}
.sponsorsLogo ul.carrierSearch {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 30px 30px 30px 80px;
  border-radius: 100px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  align-items: center;
}

.sponsorsLogo ul.carrierSearch li:nth-child(2) {
  width: 100%;
  padding: 0 30px;
}
.carrierSearch label {
  font-size: 32px;
  font-weight: 600;
  white-space: nowrap;
}
.carrierSearch input,
.carrierSearch input:hover,
.carrierSearch input:focus {
  border: 0;
  border-bottom: 1px solid #a29797;
  border-radius: 0;
  padding-left: 0;
}
.carrierSearch Button,
.carrierSearch Button:hover,
.carrierSearch Button:first-child:active {
  padding: 20px 100px;
  color: #ffd;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  background: #02a1c9;
  border-radius: 100px;
}
.FindCarriersMain {
  text-align: center;
  padding-top: 150px;
  position: relative;
  margin-bottom: 130px;
}
.FindCarriersByState .checkbox-tile::before {
  display: none;
}
.FindCarriersByState {
  background: #e0e7ef;
  border-radius: 20px;
  padding: 50px;
}
.FindCarriersByState h2 {
  position: relative;
  font-size: 32px;
  color: #000;
  font-weight: 600;
  z-index: 2;
  margin-bottom: 50px;
}
.FindCarriersByState h2 span {
  background: #e0e7ef;
  padding: 0 20px;
}
.FindCarriersByState h2::before {
  content: "";
  height: 1px;
  width: 100%;
  background: #3764b9;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
}
.FindCarriersByState .SelectedState .checkbox-group {
  justify-content: center;
  align-items: center;
}
.FindCarriersByState .checkbox-group {
  padding: 0 50px;
}
.SomeOfOurCarriers {
  padding: 100px 0;
}
.SomeOfOurCarriers h2 {
  font-size: 63px;
  font-weight: 400;
  font-family: "Hanuman", serif;
}
.join_now_button,
.join_now_button:hover,
:not(.btn-check) + .join_now_button.btn:active {
  font-size: 16px;
  background: #02a1c9;
  padding: 10px 60px;
  border: 0;
  font-weight: 600;
  color: #fff;
  box-shadow: none;
}
.AgentMain_P {
  margin-bottom: 30px;
  margin-top: 10px;
}
.AgentMain_BlueBox {
  background: #1c428b;
  border-radius: 20px;
  padding: 90px 180px;
}
.AgentMain_BlueBox h2 {
  font-size: 50px;
  font-weight: 400;
  line-height: 61px;
  color: #fff;
  font-family: "Hanuman", serif;
}
.AgentMain_BlueBox ul {
  list-style: none;
  margin-top: 25px;
  margin-bottom: 50px;
}
.AgentMain_BlueBox ul li {
  font-size: 16px;
  background: url(../../../public/images/main/orange_bulete.svg) no-repeat left
    8px;
  color: #fff;
  padding-left: 30px;
  margin-bottom: 25px;
  line-height: 27px;
}
.AgentMain_BlueBox_top_P {
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  margin: 80px 0;
  color: #555555;
  padding: 0 40px;
}
.Grow_with_us {
  padding: 0 60px;
  margin: 80px 0;
}
.Grow_with_us h2 {
  font-size: 63px;
  font-weight: 400;
  color: #000;
  text-align: center;
  font-family: "Hanuman", serif;
  margin-bottom: 50px;
}
.Grow_with_us_box:first-child {
  padding-right: 30px;
}
.Grow_with_us_box:last-child {
  padding-left: 30px;
}
.Grow_with_us_box div:first-child {
  display: flex;
}
.Grow_with_us_box div:last-child {
  background: #f0f0f0;
  padding: 50px;
  border-radius: 0 0 20px 20px;
}
.Grow_with_us_box h3 {
  color: #131a2f;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.Grow_with_us_box p {
  color: #555;
}
.becoming_an_agent {
  background: #02a1c9;
  padding: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 100px;
}
.becoming_an_agent h3 {
  font-size: 50px;
  line-height: 50px;
  font-weight: 400;
  font-family: "Hanuman", serif;
  color: #fff;
}
.becoming_an_agent button,
.becoming_an_agent button:hover,
:not(.btn-check) + .becoming_an_agent .btn:active,
.becoming_an_agent button:active {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  background: #fff;
  border-radius: 8px;
  padding: 20px 50px;
  line-height: 16px;
  border: 0;
}
.AboutMainBanner_outer {
  background: linear-gradient(
    to bottom,
    #d7e7fa 0%,
    #d7e7fa 20%,
    #fff 20%,
    #fff 80%
  );
}
.AboutMainBanner {
  /* background-color: #d7e7fa; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 100px 0;
}
.AboutMainBanner h2 {
  font-size: 67px;
  font-weight: 700;
  line-height: 61px;
  margin-bottom: 10px;
  font-family: "Hanuman", serif;
}
.AboutMainBanner p {
  font-size: 16px;
  font-weight: 400;
  color: #555555;
}
.WhoWeAre_image img {
  width: 100%;
}
.WhoWeAre_image {
  background: #ffe9c8;
  border-radius: 30px 30px 0 0;
  padding: 70px 100px 0 100px;
  top: 100px;
}
.WhoWeAre_text {
  background: #1c428b;
  padding: 100px 150px;
  color: #fff;
  text-align: center;
  border-radius: 0 0 30px 30px;
}
.WhoWeAre_image_text h2 {
  font-size: 63px;
  font-weight: 400;
  font-family: "Hanuman", serif;
}
.WhoWeAre_image_text p {
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
}
.OurValues {
  margin: 80px 0;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.OurValues h2 {
  font-size: 46px;
  font-weight: 400;
  font-family: "Hanuman", serif;
  color: #000;
}
.OurValues p {
  font-size: 17px;
  color: #555;
  width: 80%;
  line-height: 24px;
}
.simplyTransparent {
  background: #b467d8;
  border-radius: 20px;
  padding: 50px;
}
.Acting_as_one_team {
  background: #02a1c9;
  border-radius: 20px;
  padding: 50px;
}
.Make_bold_decisions {
  background: #f88b68;
  border-radius: 20px;
  padding: 50px;
}
.OurValues_threeBox {
  display: flex;
  gap: 70px;
  color: #fff;
  margin-top: 50px;
}
.OurValues_threeBox ul li:nth-child(2) {
  font-size: 36px;
  line-height: 40px;
  font-family: "Hanuman", serif;
  margin: 40px 0;
}
.how_can_we_help {
  background: #d7e7fa;
  padding: 70px 0;
}
.how_can_we_help h3 {
  font-size: 65px;
  font-family: "Hanuman", serif;
  line-height: 73px;
  width: 80%;
}
.how_can_we_help p {
  font-size: 17px;
  line-height: 27px;
  color: #555555;
  margin: 20px 0;
}
.how_can_we_help_content {
  display: flex;
  align-items: center;
}
.Our_Team_individual_box {
  width: 25%;
  background: #ffebcd;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 40px 0;
}
.Our_Team_individual_box li {
  text-align: center;
}
.Our_Team_individual_box li.Our_Team_name {
  margin-top: 40px;
  margin-bottom: 15px;
  font-size: 23px;
  font-weight: 700;
}
.Our_Team_box {
  display: flex;
  gap: 20px;
  width: 100%;
}
.Our_Team {
  margin-top: 100px;
  margin-bottom: 100px;
}
.Our_Team_details {
  font-size: 17px;
  color: #575757;
}
.Our_Team h3 {
  font-size: 46px;
  font-family: "Hanuman", serif;
  text-align: center;
}
.Our_Team > p {
  font-size: 17px;
  line-height: 24px;
  color: #555555;
  margin: 30px 0 50px 0;
  text-align: center;
  width: 70%;
}
.Our_Team {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProductsMain_outer {
  position: relative;
}
.ProductsMain_topBg {
  position: absolute;
  background: #d7e7fa url(../../../public/images/main/product_page_topBg.png)
    no-repeat bottom left;
  width: 100%;
  z-index: -1;
  height: 507px;
  padding-top: 50px;
}
.ProductsMain_contentPart {
  /* height: 900px; */
  padding-bottom: 100px;
}
.ProductsMain_contentPart h2 {
  font-size: 68px;
  font-family: "Hanuman", serif;
  font-weight: 600;
  text-align: center;
  padding-top: 120px;
}
.ProductsMain_contentPart > .container > p {
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  color: #555;
}
.ProductsMain_4Box {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  margin-top: 60px;
}
.ProductsMain_individualBox {
  width: calc(50% - 35px);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
  border-radius: 20px;
  padding: 70px;
}
.ProductsMain_individualBox h3 {
  font-size: 50px;
  line-height: 61px;
  font-family: "Hanuman", serif;
  margin-top: 50px;
}
.ProductsMain_individualBox p {
  font-size: 16px;
  color: #555;
  line-height: 27px;
}
.ProductsMain_individualBox ul {
  list-style: none;
  margin-top: 20px;
}
.ProductsMain_individualBox ul li {
  background: url(../../../public/images/main/bullete_blue.svg) no-repeat 0px
    14.5px;
  padding-left: 20px;
  font-size: 18px;
  color: #848484;
  line-height: 35px;
}
.ProductsMain_individualBox button,
.ProductsMain_individualBox button:hover,
.ProductsMain_individualBox button:active,
.ProductsMain_individualBox .btn:focus-visible,
.ProductsMain_individualBox :not(.btn-check) + .btn:active {
  margin-top: 35px;
  border: 1px solid #02a1c9;
  border-radius: 8px;
  padding: 20px 70px;
  font-size: 20px;
  color: #02a1c9;
  background: #fff;
  box-shadow: none;
  font-weight: 600;
}
.StatesWeServe_mainProduct {
  background: #f2f2f2;
  padding: 100px 0;
  text-align: center;
}
.StatesWeServe_mainProduct h2 {
  font-size: 65px;
  line-height: 73px;
  font-family: "Hanuman", serif;
}
.StatesWeServe_mainProduct p {
  font-size: 17px;
  color: #555;
  margin-bottom: 70px;
}

.FAQMain_outer {
  position: relative;
}
.FAQMain_topBg {
  position: absolute;
  background: #d7e7fa;
  width: 100%;
  z-index: -1;
  height: 270px;
  padding-top: 50px;
}
.FAQMain_contentPart {
  /* height: 900px; */
  padding-bottom: 100px;
}
.FAQMain_contentPart h3 {
  font-size: 68px;
  font-family: "Hanuman", serif;
  font-weight: 600;
  text-align: center;
  padding-top: 100px;
}
.FAQMain_contentPart > .container > p {
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  color: #555;
  padding: 0 25%;
}
.FAQ_content {
  margin-top: 160px;
}
.FAQ_content h4 {
  font-size: 33px;
  line-height: 66px;
  font-family: "Hanuman", serif;
  text-align: center;
}

/* custom check box gray start */
.grayActiveDeactive .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* max-width: 600px; */
  user-select: none;
  gap: 6px;
}
.grayActiveDeactive .checkbox-group > * {
  margin: 0;
}
.grayActiveDeactive .checkbox-group-legend {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9c9c9c;
  text-align: center;
  line-height: 1.125;
  margin-bottom: 1.25rem;
}
.grayActiveDeactive .checkbox-input {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.grayActiveDeactive .checkbox-input[type="checkbox"]:checked + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #02a1c9;
}
.grayActiveDeactive .checkbox-input[type="radio"]:checked + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #02a1c9;
}
.grayActiveDeactive
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #02a1c9;
}

.grayActiveDeactive .checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #f90;
  border-color: #f90;
}
.grayActiveDeactive
  .checkbox-input[type="checkbox"]:checked
  + .checkbox-tile
  .checkbox-icon,
.checkbox-input[type="checkbox"]:checked + .checkbox-tile .checkbox-label {
  color: #02a1c9;
}

.grayActiveDeactive .checkbox-input:focus + .checkbox-tile {
  border-color: #02a1c9;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc; */
}
.grayActiveDeactive .checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}
.grayActiveDeactive .checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  min-height: 36px;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  padding: 0 24px;
}
.grayActiveDeactive .checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 13px;
  height: 13px;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: -7px;
  right: -7px;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: none;
}
.grayActiveDeactive .checkbox-tile:hover {
  border-color: #f0f0f0;
}
.grayActiveDeactive .checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}
.grayActiveDeactive .checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
  padding-top: 5px;
}
.grayActiveDeactive .checkbox-icon img {
  width: 25px;
  height: 24px;
}
.grayActiveDeactive .checkbox-label {
  color: #868a8e;
  transition: 0.375s ease;
  text-align: center;
  font-size: 14px;
}
.grayActiveDeactive .checkbox-group .checkbox:first-child {
  margin-left: 0;
}
.grayActiveDeactive .checkbox-group .checkbox:last-child {
  margin-right: 0;
}
.grayActiveDeactive .checkbox-input:checked + .checkbox-tile > .checkbox-icon {
  opacity: 0.5;
}
.grayActiveDeactive
  .twoOptions
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile {
  border-color: #f0f0f0;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); */
  color: #2d2d2d;
  background-color: #f0f0f0;
}
.grayActiveDeactive
  .twoOptions
  .checkbox-input[type="radio"]:checked
  + .checkbox-tile
  .checkbox-label {
  color: #2d2d2d;
}
/* custom check box with gray end */

.FAQMainAccordionContent {
  margin-top: 70px;
}
.FAQMainAccordionContent .accordion-item {
  border: 0;
  border-top: #cccccc 1px solid;
  border-radius: 0;
}
.FAQMainAccordionContent .accordion-item:last-child {
  border-bottom: #cccccc 1px solid;
}
.FAQMainAccordionContent .accordion-button {
  padding: 5px 0;
  font-size: 20px;
  font-family: "Hanuman", serif;
}
.FAQMainAccordionContent .accordion-body {
  padding: 10px 0;
  font-size: 16px;
  line-height: 35px;
}
.FAQMainAccordionContent .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}
.FAQMainAccordionContent button:focus:not(:focus-visible),
.FAQMainAccordionContent .accordion-button:focus {
  box-shadow: none;
}
.FAQMainAccordionContent .accordion-button::after {
  background-image: url(../../../public/images/main/accordian_plus.svg);
}
.FAQMainAccordionContent .accordion-button:not(.collapsed)::after {
  background-image: url(../../../public/images/main/accordian_minus.svg);
}

.ContactMain_contentPart_box {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
  border-radius: 20px;
  padding: 70px 100px;
  margin-top: 50px;
  border-bottom: 1px solid #c6c6c6;
}
.ContactMain_topBg {
  position: absolute;
  background: #d7e7fa;
  width: 100%;
  z-index: -1;
  height: 380px;
  padding-top: 50px;
}
.ContactMain_contentPart_box ul {
  display: flex;
  gap: 50px;
}
.ContactMain_contentPart_box ul li h4 {
  font-size: 40px;
  margin-bottom: 10px;
}
.ContactMain_contentPart_box ul li p {
  font-size: 26px;
  line-height: 31px;
  color: #2d2d2d;
}
.TextUs {
  background: #ff9900;
  color: #fff;
  padding: 15px 35px;
  position: fixed;
  bottom: 25px;
  right: 30px;
  border-radius: 20px 20px 0 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
}
.TextUs_box {
  width: 467px;
  background: #fff;
  border-radius: 20px 20px 0px 20px;
  padding: 40px 55px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: fixed;
  bottom: 25px;
  right: 30px;
  display: none;
}
.text_usToggle .TextUs_box {
  display: block;
}

.ProductsMain_individualBox_color {
  border-radius: 50%;
  width: 190px;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Our_Team_img img {
  width: 70%;
}
.TextUs_box_top {
  position: relative;
}
.TextUs_box_top h1 {
  font-size: 30px;
  font-weight: 400;
  font-family: "Hanuman", serif;
  padding-right: 30px;
  line-height: 31px;
  margin-bottom: 20px;
}
.popUp_close {
  position: absolute;
  right: -30px;
  top: -10px;
  cursor: pointer;
}
.TextUs_box p {
  font-size: 13px;
  font-weight: 400;
  color: #555555;
}
/* main site css end */

/* 1700 */

@media (max-width: 1700px) {
  .ContactMain_contentPart_box ul li h4 {
    font-size: 37px;
  }
  .ContactMain_contentPart_box ul li p {
    font-size: 24px;
    line-height: 28px;
  }
  .ContactMain_contentPart_box ul {
    gap: 40px;
  }
  .FAQ_content {
    font-size: 30px;
    line-height: 60px;
  }
  .FAQMain_contentPart h3 {
    font-size: 58px;
  }
  .StatesWeServe_mainProduct {
    padding: 80px 0;
  }
  .StatesWeServe_mainProduct h2 {
    font-size: 55px;
    line-height: 73px;
  }
  .StatesWeServe_mainProduct p {
    font-size: 16px;
    margin-bottom: 60px;
  }
  .ProductsMain_contentPart h2 {
    font-size: 58px;
  }
  .ProductsMain_contentPart img {
    width: 95px;
    max-height: 95px;
  }
  .ProductsMain_individualBox h3 {
    font-size: 45px;
    line-height: 55px;
  }
  .ProductsMain_individualBox p {
    font-size: 15px;
  }
  .ProductsMain_individualBox ul li {
    font-size: 16px;
    background: url(../../../public/images/main/bullete_blue.svg) no-repeat 0px
      13.5px;
  }
  .ProductsMain_individualBox button,
  .ProductsMain_individualBox button:hover,
  .ProductsMain_individualBox button:active,
  .ProductsMain_individualBox .btn:focus-visible,
  .ProductsMain_individualBox :not(.btn-check) + .btn:active {
    padding: 14px 60px;
    font-size: 18px;
  }
  .Our_Team_details {
    font-size: 16px;
  }
  .Our_Team_individual_box li.Our_Team_name {
    margin-top: 30px;
    margin-bottom: 12px;
    font-size: 21px;
  }
  .Our_Team_individual_box {
    padding: 40px 0 30px 0;
  }
  .Our_Team {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .how_can_we_help h3 {
    font-size: 55px;
    line-height: 63px;
  }

  .WhoWeAre_image_text h2 {
    font-size: 53px;
  }
  .AboutMainBanner h2 {
    font-size: 55px;
  }
  .AboutMainBanner h2 {
    font-size: 55px;
    line-height: 60px;
  }
  .becoming_an_agent h3 {
    font-size: 40px;
    line-height: 40px;
  }
  .Grow_with_us h2 {
    font-size: 53px;
  }
  .AgentMain_BlueBox ul li {
    font-size: 14px;
    line-height: 25px;
  }
  .AgentMain_BlueBox h2 {
    font-size: 42px;
    line-height: 51px;
  }
  .AgentMain_BlueBox_top_P {
    font-size: 23px;
  }
  .SomeOfOurCarriers h2 {
    font-size: 53px;
  }
  .Risker_final_logo {
    width: 148px;
  }
  .headerMainContainer .mainMenu {
    gap: 40px;
    font-size: 14px;
  }
  .headerMainContainer .mainMenu a {
    font-size: 14px;
  }
  .headerMainContainer .right .phone {
    font-size: 14px;
  }
  .login_main_header,
  .login_main_header:hover {
    padding: 9px 20px;
    font-size: 14px;
  }
  .trial_main_header,
  .trial_main_header:hover {
    padding: 9px 20px;
    font-size: 14px;
  }
  .headerMainContainer {
    margin: 0 70px;
  }
  .mainBannerContainer h1 {
    font-size: 55px;
    line-height: 60px;
  }

  .OurCoverages h2 {
    font-size: 51px;
  }
  .sponsorsLogo {
    padding: 0 70px;
  }
  .sponsorsLogo ul {
  }
  .sponsorsLogo ul img {
    width: 80%;
  }
  .OurCoverages_4Ppart img {
    width: 70%;
  }
  .Discover_why_content .left h2 {
    font-size: 45px;
  }
  .Discover_why_left_content_area h2 {
    font-size: 45px;
  }
  .SwiperSlideContent ul .name {
    font-size: 14px;
  }
  .SwiperSlideContent ul .date {
    font-size: 12px;
  }
  .SwiperSlideContent .bodyText {
    font-size: 16px;
  }
  .map_area_content h3 {
    font-size: 45px;
  }

  .innerBanner_01 h1 {
    font-size: 55px;
    line-height: 60px;
  }
  .carriersMainContainer h1 {
    font-size: 55px;
    line-height: 60px;
  }
}

/* 1440 */
@media (max-width: 1440px) {
  .headerMainContainer {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
  }
  .headerMainContainer .left {
    display: flex;
    gap: 30px;
  }
  .Risker_final_logo {
    width: 121px;
  }
  .headerMainContainer .mainMenu {
    gap: 20px;
    font-size: 15px;
  }
}

/* 1280 */
@media (max-width: 1280px) {
}

/* tab */
@media (max-width: 991px) {
  .ProductsMain_contentPart img {
    width: 60px;
  }
  .ProductsMain_individualBox_color {
    width: 130px;
    height: 130px;
  }
  .ContactMain_contentPart_box .row .col-lg-6:first-child {
    margin-bottom: 50px;
  }
  .FAQMain_contentPart > .container > p {
    padding: 0;
  }
  .StatesWeServe_mainProduct {
    padding: 70px 0;
  }
  .StatesWeServe_mainProduct h2 {
    font-size: 45px;
    line-height: 53px;
  }
  .StatesWeServe_mainProduct p {
    font-size: 15px;
    margin-bottom: 50px;
  }
  .StatesWeServe_mainProduct img {
    width: 80%;
  }
  .ProductsMain_individualBox {
    padding: 30px;
    width: calc(50% - 25px);
  }
  .ProductsMain_individualBox ul li {
    font-size: 15px;
    line-height: 30px;
  }
  .ProductsMain_individualBox h3 {
    font-size: 35px;
    line-height: 45px;
  }
  .ProductsMain_individualBox button,
  .ProductsMain_individualBox button:hover,
  .ProductsMain_individualBox button:active,
  .ProductsMain_individualBox .btn:focus-visible,
  .ProductsMain_individualBox :not(.btn-check) + .btn:active {
    padding: 12px 40px;
    font-size: 17px;
  }
  .ProductsMain_4Box {
    gap: 25px;
  }
  .Our_Team {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .Our_Team > p {
    width: 90%;
    margin: 20px 0 30px 0;
  }
  .Our_Team h3 {
    font-size: 36px;
  }
  .Our_Team_box {
    flex-wrap: wrap;
  }
  .Our_Team_individual_box {
    width: calc(50% - 20px);
  }
  .how_can_we_help h3 {
    font-size: 45px;
    line-height: 53px;
    width: 100%;
  }
  .how_can_we_help .container .row > div:last-child {
    display: none;
  }
  .how_can_we_help .container .row > div:first-child {
    width: 100%;
  }
  .OurCoverages_4Ppart img {
    width: 50%;
  }
  .OurCoverages_4Ppart {
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .OurCoverages_4Ppart ul {
    width: 45%;
  }
  .desktop_show_tab_off {
    display: none;
  }
  .headerMainContainer .mainMenu.desktop_show_tab_off {
    display: none;
  }
  .desktop_off_tab_show {
    display: flex;
  }
  .tab_off_mobile_show {
    display: none;
  }
  .mainBannerContainer h1 {
    font-size: 33px;
    line-height: 40px;
    width: 400px;
  }
  .Book_a_Demo,
  .Book_a_Demo:hover,
  .Book_a_Demo:active,
  .Book_a_Demo.btn.btn-primary:active {
    font-size: 14px;
    padding: 11px 25px;
    margin-top: 15px;
  }
  .headerMainContainer {
    padding-top: 10px;
  }

  .loginBox .right {
    padding-left: 50px;
  }
  .planBox {
    width: 200px;
  }
  .planBox .amount {
    margin-bottom: 30px;
  }
  .twoPlan {
    gap: 20px;
  }
  .AdminDashboardFourBox ul {
    flex-wrap: wrap;
  }
  .AdminDashboardFourBox ul li {
    width: 47%;
  }
  .sponsorsLogo {
    padding: 0 20px;
    top: -52px;
  }
  .sponsorsLogo ul img {
    width: 65%;
  }
  .Discover_why_outer {
    display: flex;
    flex-direction: column;
  }
  .Discover_why_left {
    width: 100%;
    border-radius: 0;
  }
  .Discover_why_left_content_area {
    width: 100%;
    text-align: center;
    padding: 100px;
    margin: 0;
  }
  .Discover_why_right {
    width: 100%;
    padding: 50px;
  }
  .Discover_why_left_content_area h2::after {
    bottom: 25px;
  }
  .map_area_content div {
    padding-left: 20px;
  }
  .map_area_content h3 {
    font-size: 40px;
  }
  .how_it_works_content_area {
    display: flex;
    flex-direction: column;
  }
  .how_it_works .left {
    width: 100%;
    padding-right: 0px;
    order: 2;
  }
  .how_it_works .right {
    width: 100%;
    text-align: center;
    order: 1;
  }
  .how_it_work_img {
    width: 50%;
  }
  .FooterMainContainer_inner {
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .FooterMainContainer_inner > div:last-child {
    width: 100%;
  }
  .FooterMainContainer_inner > div:first-child {
    width: 100%;
    padding: 0;
  }
  .FooterMainContainer {
    background: #4b5156;
  }
  .right_mobile {
    background: #2d2d2d;
    color: #fff;
    padding: 20px 0;
  }
  .right_mobile ul {
    display: flex;
    justify-content: space-between;
  }
  .right_mobile ul li {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .footer_bottom {
    margin-top: 20px;
  }
  .FooterMainContainer_inner_content > div {
    width: 33%;
  }
  .FooterMainContainer_inner .left .head {
    font-size: 18px;
  }
  .FooterMainContainer_inner .left a {
    font-size: 16px;
  }
  .OffcanvasMenu li a {
    text-decoration: none;
    line-height: 20px;
    color: #000;
    font-weight: 600;
  }
  .OffcanvasMenu li {
    line-height: 50px;
    border-bottom: 1px solid #8cb9ef;
  }
  .innerBanner_01 h1 {
    max-width: 50%;
    font-size: 33px;
    line-height: 40px;
  }
  .innerBanner_01 > p {
    font-size: 18px;
  }
  .monthly,
  .yearly {
    width: 50%;
  }
  .monthly_yearly {
    padding: 20px;
    gap: 35px;
  }
  .help_you_box {
    background: #1c428b
      url(../../../public/images/main/help_you_focus_box_background.svg)
      no-repeat 20px bottom;
    margin-top: 200px;
  }
  .help_you_box h3 {
    font-size: 50px;
    line-height: 53px;
  }
  .monthly_yearly h3 {
    font-size: 48px;
  }
  .monthly_yearly .price {
    font-size: 58px;
  }
  .monthly_yearly .monthly .top,
  .monthly_yearly .yearly .top {
    padding: 30px 0 40px 0;
  }
  .mainLogin_whiteBox_Inner .left {
    display: none;
  }
  .mainLogin_whiteBox_Inner .right {
    width: 100%;
  }
  .main_login_bg {
    background: #d7e7fa url(/public/images/main/main_login_bg.svg) no-repeat
      bottom right 50px;
  }
  .book_a_demo_content_ul h3 {
    font-size: 35px;
    line-height: 45px;
  }
  .book_a_demo_content_inner .orange_circle {
    width: 54px;
    height: 54px;
    font-size: 30px;
  }
  .book_a_demo_content_inner {
    padding: 70px 20px;
  }
  .grayBox {
    padding: 50px;
  }
  .book_a_demo_content_ul_1st::after {
    top: 60px;
    left: 28px;
    height: calc(100% - 50px);
  }
  .book_a_demo_content_ul {
    padding-bottom: 10px;
  }
  .carriersMainContainer h1 {
    font-size: 33px;
    line-height: 40px;
    width: 100%;
  }
  .carriersMainContainer p {
    width: 100%;
  }
  .FindCarriersByState .checkbox-group {
    padding: 0px;
  }
  .FindCarriersByState h2 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .FindCarriersByState h2 span {
    padding: 0 10px;
  }
  .FindCarriersByState {
    padding: 30px;
    margin-top: 60px;
  }
  .AgentMain_BlueBox_top_P {
    font-size: 20px;
    padding: 0px;
    margin: 40px 0;
  }
  .AgentMain_BlueBox {
    padding: 50px;
  }
  .AgentMain_BlueBox h2 {
    font-size: 32px;
    line-height: 41px;
  }
  .AgentMain_BlueBox ul li {
    margin-bottom: 20px;
  }
  .Grow_with_us_box:first-child {
    padding-right: 10px;
  }
  .Grow_with_us_box:last-child {
    padding-left: 10px;
  }
  .Grow_with_us_box div:last-child {
    padding: 30px;
    min-height: 180px;
  }
  .Grow_with_us_box h3 {
    font-size: 22px;
  }
  .Grow_with_us h2 {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .Grow_with_us {
    margin: 50px 0;
  }
  .becoming_an_agent {
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
  }
  .becoming_an_agent h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .WhoWeAre_text {
    background: #1c428b;
    padding: 50px 50px;
  }
  .WhoWeAre_image_text h2 {
    font-size: 43px;
  }
  .WhoWeAre_image_text p {
    font-size: 18px;
  }
  .simplyTransparent,
  .Acting_as_one_team,
  .Make_bold_decisions {
    padding: 20px;
  }
  .OurValues_threeBox {
    gap: 20px;
  }
  .OurValues_threeBox ul li img {
    width: 40%;
  }
  .OurValues_threeBox ul li:nth-child(2) {
    font-size: 26px;
    line-height: 30px;
    margin: 20px 0;
  }
}

@media (max-width: 767px) {
  .tab_show_mobile_off {
    display: none;
  }
  .tab_off_mobile_show {
    display: flex;
  }
  .desktop_off_mobile_show {
    display: flex;
  }
  .desktop_show_mobile_off {
    display: none;
  }
  .OurCoverages h2 {
    font-size: 40px;
  }
  .loginBox .left {
    width: 100%;
  }
  .loginBox .right {
    display: none;
  }
  .container_outer_01 {
    background: transparent;
  }
  .AgentUpdateProfile_bottom {
    flex-direction: column;
  }
  .AgentUpdateProfile_bottom .left {
    width: 100%;
  }
  .AgentUpdateProfile_bottom .right {
    width: 100%;
    /* min-height: 400px; */
  }
  .select_plan_process ul {
    display: flex;
  }
  .select_plan_process ul li {
    width: 100px;
  }
  .select_plan_process li::after {
    width: 32px;
    height: 1px;
    left: inherit;
    bottom: inherit;
    top: 20px;
    right: -16px;
    top: 15px;
  }
  .select_plan_process span {
    text-align: center;
    line-height: 15px;
    margin-top: 5px;
  }
  .select_plan_process {
    margin-top: 50px;
  }
  .login_top h3 {
    margin-top: 0px;
  }
  .sponsorsLogo {
    padding: 0 10px;
  }
  .sponsorsLogo ul img {
    width: 85%;
  }
  .sponsorsLogo ul {
    padding: 10px 30px;
  }
  .mainBanner img {
    margin-top: 140px;
  }
  .Discover_why_left_content_area {
    padding: 50px;
  }
  .Discover_why_left_content_area h2 {
    font-size: 25px;
    line-height: 40px;
  }
  .Discover_why_left_content_area h2 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .carriers_item ul {
    /* flex-direction: column; */
  }
  .carriers_item ul li {
    width: calc(50% - 20px);
  }
  .map_area ul {
    flex-direction: column;
  }
  .map_area ul li {
    width: 100%;
    text-align: center;
  }
  .map_area {
    padding: 50px 0;
  }
  .carriersMainBg {
    align-items: flex-start;
  }

  .sponsorsLogo ul.carrierSearch {
    padding: 20px 10px 20px 10px;
    border-radius: 5px;
    flex-direction: column;
    gap: 20px;
  }
  .carrierSearch label {
    font-size: 22px;
  }
  .carrierSearch Button,
  .carrierSearch Button:hover,
  .carrierSearch Button:first-child:active {
    font-size: 16px;
    line-height: 16px;
  }
  .SomeOfOurCarriers {
    padding: 50px 0;
  }
  .SomeOfOurCarriers h2 {
    font-size: 30px;
  }

  .OurValues_threeBox {
    flex-direction: column;
  }
  .OurValues_threeBox ul li img {
    width: 20%;
  }
  .WhoWeAre_image_text h2 {
    font-size: 33px;
  }
  .WhoWeAre_image_text p {
    font-size: 17px;
    line-height: 30px;
  }
  .WhoWeAre_text {
    background: #1c428b;
    padding: 50px 30px;
  }
  .OurValues h2 {
    font-size: 46px;
  }
}

/* mobile */
@media (max-width: 576px) {
  .ContactMain_contentPart_box ul {
    flex-direction: column;
    gap: 20px;
  }
  .ContactMain_contentPart_box {
    padding: 30px 50px;
  }
  .ContactMain_contentPart_box img {
    width: 70px;
  }
  .ContactMain_topBg {
    height: 350px;
  }
  .ContactMain_contentPart_box ul li h4 {
    font-size: 30px;
  }
  .ContactMain_contentPart_box ul li p {
    font-size: 20px;
  }

  .FAQ_content h4 {
    font-size: 25px;
  }
  .AboutMainBanner h2,
  .FAQMain_contentPart h3 {
    font-size: 35px;
  }
  .ProductsMain_individualBox {
    width: 100%;
  }
  .ProductsMain_contentPart img {
    width: 50px;
  }
  .ProductsMain_individualBox_color {
    width: 100px;
    height: 100px;
  }
  .ProductsMain_individualBox h3 {
    margin-top: 30px;
  }
  .AboutMainBanner_outer {
    background: linear-gradient(
      to bottom,
      #d7e7fa 0%,
      #d7e7fa 20%,
      #fff 20%,
      #fff 80%
    );
  }
  .AboutMainBanner {
    padding: 40px 0 60px 0;
  }
  .Our_Team_individual_box {
    width: calc(100%);
  }
  .how_can_we_help h3 {
    font-size: 35px;
    line-height: 43px;
    width: 100%;
  }
  .how_can_we_help {
    padding: 40px 0;
    text-align: center;
  }
  .Grow_with_us_box:first-child {
    margin-bottom: 50px;
  }
  .carriers_item ul {
    flex-direction: column;
  }
  .carriers_item ul li {
    width: 100%;
  }
  .OurCoverages_4Ppart ul {
    width: 100%;
  }
  .OurCoverages_4Ppart img {
    width: 35%;
  }
  .OurCoverages_4Ppart .head {
    margin-top: 10px;
  }
  .select_plan_process ul li {
    width: 85px;
  }
  .twoPlan {
    flex-direction: column;
  }
  .sponsorsLogo {
    top: -31px;
  }
  .OurCoverages {
    padding-top: 70px;
    margin-bottom: 70px;
  }
  .Discover_why_swiper .swiper-slide {
  }

  .Discover_why_left_content_area h2::after {
    bottom: -60px;
    right: -60px;
    background-size: 50px;
  }
  .ReadAllReviews {
    font-size: 14px;
    padding: 15px 30px;
  }
  .button_area {
    margin-top: 40px;
  }
  .FooterMainContainer_inner_content > div {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .FooterMainContainer_inner .left .FooterMainContainer_inner_content {
    flex-direction: column;
  }
  .FooterMainContainer_inner .left a {
    line-height: 28px;
  }
  .FooterMainContainer_inner .left .head {
    margin-bottom: 10px;
  }
  .copyright {
    padding-left: 0px;
    display: flex;
    justify-content: center;
  }
  .footer_bottom {
    text-align: center;
  }
  .how_it_works h3 {
    font-size: 40px;
    text-align: center;
    margin: 10px 0;
  }
  .how_it_work_img {
    width: 80%;
  }
  .how_it_works p {
    text-align: center;
  }
  .how_it_works {
    padding: 50px 0;
  }
  .right_mobile ul {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .right_mobile ul li:last-child img {
    width: 30px;
  }
  .right_mobile ul li:last-child {
    gap: 10px;
  }
  .innerBanner_01 h1 {
    max-width: 90%;
    font-size: 30px;
    line-height: 38px;
    margin-top: 50px;
  }
  .innerBanner_01 > p {
    font-size: 16px;
    margin: 20px 0 30px 0;
  }

  .monthly_yearly_outer {
    position: inherit;
    padding: 50px 30px 30px 30px;
  }
  .monthly_yearly {
    position: inherit;
    flex-direction: column;
  }
  .monthly,
  .yearly {
    width: 100%;
  }
  .monthly_yearly h3 {
    font-size: 38px;
  }
  .monthly_yearly .price {
    font-size: 38px;
  }
  .monthly_yearly .monthly .top,
  .monthly_yearly .yearly .top {
    padding: 30px 0 25px 0;
  }
  .monthly_yearly .bottom {
    padding: 20px;
  }
  .monthly_yearly .bottom ul li {
    font-size: 17px;
    margin-bottom: 20px;
  }
  .help_you_box {
    margin-top: 0px;
    padding: 50px;
    /* padding-bottom: 300px; */
    background: #1c428b;
    text-align: center;
  }
  .help_you_box h3 {
    font-size: 35px;
    width: 100%;
    line-height: 50px;
  }
  .grayBox {
    padding: 10px;
  }
  .grayBox .button_container button {
    background: #02a1c9;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 600;
  }
  .button_container {
    text-align: center;
  }
  .grayBox .row {
    margin-bottom: 0px;
  }
  .book_a_demo_content_ul h3 {
    font-size: 30px;
    line-height: 35px;
    font-size: 30px;
    line-height: 35px;
  }
  .carriersMainContainer {
    position: absolute;
    top: 35px;
  }
  .AgentMain_BlueBox h2 {
    font-size: 30px;
    line-height: 41px;
  }
  .AgentMain_BlueBox {
    padding: 30px;
  }
  .join_now_button_cover {
    text-align: right;
    padding-right: 30px;
  }
  .join_now_button {
    padding: 10px 30px;
  }
  .OurValues h2 {
    font-size: 36px;
  }
}

/* small mobile */
@media (max-width: 414px) {
  .select_plan_process ul li {
    width: 85px;
  }
  .AgentMain_BlueBox h2 {
    font-size: 30px;
    line-height: 41px;
  }
}
